export default {
  'Enable webhook?': '¿Habilitar webhook?',
  'Enable': 'Habilitar',
  'enablingWebhookStartEvents':
    'Al habilitar este punto final de webhook, comenzará a recibir notificaciones sobre eventos.',
  'Disable webhook?': '¿Deshabilitar webhook?',
  'Disable': 'Deshabilitar',
  'disablingWebhookStopEvents':
    'Al deshabilitar temporalmente este punto final de webhook, dejará de recibir notificaciones sobre eventos.',
  'Remove webhook?': '¿Eliminar webhook?',
  'removingWebhookNoEventSent':
    'Eliminar este webhook significa que no se enviarán mensajes de webhook cada vez que ocurra este evento dentro de su organización.',
  'Add endpoint': 'Agregar endpoint',
  'Endpoint URL': 'URL del endpoint',
  'Secret': 'Secreto',
  'Select events': 'Seleccionar eventos',
  'Add': 'Agregar',
  'Edit endpoint': 'Editar endpoint',
  'Save': 'Guardar',
  'The webhook endpoint was saved successfully': 'El endpoint del webhook se guardó correctamente.',
  'The webhook endpoint was added successfully': 'El endpoint del webhook se añadió correctamente.',
  'Failed to retrieve webhook secret': 'No se pudo recuperar el secreto del webhook.',
  'URL': 'URL',
  'Events': 'Eventos',
  'Status': 'Estado',
  'Created': 'Creado',
  'Set': 'Establecido',
  'Missing': 'Faltante',
  'Active': 'Activo',
  'Disabled': 'Desactivado',
  'Edit webhook': 'Editar webhook',
  'Disable webhook': 'Desactivar webhook',
  'Enable webhook': 'Activar webhook',
  'Remove webhook': 'Eliminar webhook',
  'No webhooks added': 'No se han añadido webhooks',
  'toGetStartedClick': 'Para empezar, haz clic en “Añadir punto de conexión” para agregar uno.',
}
