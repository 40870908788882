<script setup lang="ts">
import { DialogBtn } from '@keyo/ui'
import ModalCard from '@/components/modals/components/ModalCard.vue'
import { useModal } from '@/composables/useModal'

const props = defineProps({
  onConfirm: Function,
  onCancel: Function,
})

const modal = useModal()

async function leave() {
  await props.onConfirm?.()
  await modal.hide()
}
async function cancel() {
  await props.onCancel?.()
  await modal.hide()
}
</script>

<template>
  <ModalCard
    :heading="$t('components.modals.Leave this window?')"
    icon-color="lavender"
    icon="question-circle"
  >
    {{ $t('components.modals.leaveWithoutSaving?') }}
    <template #buttons>
      <DialogBtn kind="secondary" @click="cancel">{{ $t('common.stay') }}</DialogBtn>
      <DialogBtn @click="leave">{{ $t('common.leave') }}</DialogBtn>
    </template>
  </ModalCard>
</template>
