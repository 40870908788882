import { computed, ref } from 'vue'
import apiCreds from '../api/api-creds'
import { useModal } from '@/composables/useModal'
import { useCurrentRouteEntities } from '@/composables/useCurrentRouteEntities'
import { toast } from '@keyo/ui'
import { useI18n } from 'vue-i18n'
import { useOrganizationsStore } from '@/store'

type Key = {
  createdAt: string
  name: string
} | null

export default function () {
  const { t, locale } = useI18n()
  const modal = useModal()
  const { organization } = useCurrentRouteEntities()
  const orgs = useOrganizationsStore()

  const isProcessing = ref(false)

  const key = computed<Key>(() => {
    if (!organization.value.auth_keys[0]?.token) {
      return null
    }
    const authKey = organization.value.auth_keys[0]
    return {
      createdAt: new Date(authKey.secret_creation_date).toLocaleString(locale.value),
      name: 'Secret key',
    }
  })

  async function create() {
    try {
      if (isProcessing.value) return
      isProcessing.value = true

      const resp = await apiCreds.rotate(organization.value.id)
      modal.show('SecretCreated', { isCustomStyle: true, secret: resp.data.token })

      orgs.fetchById(organization.value.id, { redirect: false })
    } catch (e) {
      console.error(e)
      toast.show(() => t('errors.somethingBroken'), 'error')
    } finally {
      isProcessing.value = false
    }
  }

  return {
    isProcessing,
    create,
    key,
  }
}
