<script setup lang="ts">
import ModalCard from '@/components/modals/components/ModalCard.vue'
import { DialogBtn } from '@keyo/ui'
import { useModal } from '@/composables/useModal'

const modal = useModal()
</script>
<template>
  <ModalCard
    stick-to-bottom
    :heading="$t('All done!')"
    :description="$t('modules.auth.pages.forgotPasswordSet.youCanNowUseYourNewPassword')"
    icon="check-circle"
    icon-color="green"
  >
    <template #buttons>
      <DialogBtn tag="router-link" :to="{ name: 'login' }" @click="modal.hide()">
        {{ $t('buttons.gotIt') }}
      </DialogBtn>
    </template>
  </ModalCard>
</template>
