import { defineStore } from 'pinia'
import { toast } from '@keyo/ui'
import { i18n, i18nUtils } from '@keyo/core/i18n'

import account from '../api'
import { checkInvitations, getOrganizationPublicData, updateInvitation } from '@/api/organization'
import { useOrganizationsStore } from '@/store/organizations'
import { useLanguage } from '@/composables/useLanguage'
import { formatDob } from '@/utils'
import type { SignInMethod } from '@/api/auth.ts'
import type { Code } from '@/types/code.ts'
import { getMethodI18nValue } from '@/modules/account/utils'
import type { LocaleKey } from '@keyo/core/i18n'
import type {
  MEMBERSHIP_STATUS_NAME,
  MEMBERSHIP_STATUS_VALUE,
  Organization,
  RoleType,
} from '@/modules/organization/types/model.ts'
import { MEMBERSHIP_STATUS } from '@/modules/organization/types/model.ts'
import type { INVITE_STATUS_NAME, INVITE_STATUS_VALUE } from '@/types/invite.ts'
import { INVITE_ACTIONS, INVITE_STATUS } from '@/types/invite.ts'
import { toLocaleString } from '@/utils/stringUtils'
import { getOrganizationRequirements } from '@/modules/organization/utils'
import type { CountryCodeType } from '@keyo/core'
import { CountriesService } from '@keyo/core'
import type { AverageMetricsResponse, ScanMetricsResponse } from '@/modules/metrics/api/metrics.ts'
import metricsApi from '@/modules/metrics/api/metrics.ts'

const profileCompleteFields = ['first_name', 'last_name'] as const

export type UI_SETTINGS_KEYS =
  | 'hide_unverified_methods_banner'
  | 'hide_empty_methods_banner'
  | 'user_locale'

export interface AddressBookType {
  id: number
  user: number
  title: string
  country: CountryCodeType
  countryName?: string
  state: string
  city: string
  street: string
  zip_code?: string
  street_address_line?: string
}

export interface Profile {
  id: number
  photo: string
  devices: {
    id: number
    name: string
  }[]
  role: RoleType
  first_name: string
  last_name: string
  full_name: string
  middle_name: string
  display_name: string
  date_of_birth: string | null
  email: string
  phone: string
  country: string
  date_joined: string
  permissions: string[]
  owner_org_ids: string[]
  org_limit: number
  is_biometric_exists: boolean
  is_confirmed_email: boolean
  is_confirmed_phone: boolean
  preferable_mfa_method: 'email' | 'phone'
  ui_settings: Partial<Record<UI_SETTINGS_KEYS, unknown>>
  biometric_policy: string | null
  privacy_policy: string | null
  terms_of_use: string | null
  status: UserStatus
  last_enrollment_date: string | null
  last_identification_date: string | null
  last_deleted_biometric_date: string | null
  language: string
  displayDob: string
  initials: string
  isActiveAdmin: boolean
}

export type InvitationOrganization = Pick<
  Organization,
  | 'name'
  | 'is_active'
  | 'business_email'
  | 'business_contact_first_name'
  | 'business_contact_last_name'
  | 'id'
  | 'business_phone'
  | 'logo'
  | 'background'
  | 'terms_of_use'
  | 'privacy_policy'
  | 'payment_provider_ids'
  | 'localization'
  | 'settings'
>

export type UserStatus = {
  name: MEMBERSHIP_STATUS_NAME
  value: MEMBERSHIP_STATUS_VALUE
}

export type InvitationStatus = { value: INVITE_STATUS_VALUE; name: INVITE_STATUS_NAME }

export interface Invitation {
  id: number
  status: InvitationStatus
  organization: InvitationOrganization
  creation_date: string
  update_date: string
  hasPendingRequirements?: boolean
}

export interface CodeBody {
  code: Code
}

export interface DeleteByMethodBody extends CodeBody {
  client_id: string
}

export interface PhoneSetBody extends CodeBody {
  phone: string
  client_id: string
}

export interface EmailSetBody extends CodeBody {
  email: string
  client_id: string
}

export interface ChangePhoneBody {
  new_phone?: string
  code_from_old_phone?: Code
  code_from_new_phone?: Code
  captcha_token?: string
  client_id_from_old_phone?: string
  client_id_from_new_phone?: string
}

export interface ChangeEmailBody {
  new_email?: string
  code?: string
  code_from_new_email?: Code
  code_from_old_email?: Code
  client_id_from_old_email?: string
  client_id_from_new_email?: string
}

export type ProfilePhoto = File | string

export interface MfaPhoneRequestBody {
  phone: string
  captcha_token: string
}

export interface VerifyMethodBody extends CodeBody {
  method: string
  client_id: string
}

interface JoinedBanner {
  organizationId: number
  organizationName: string
}

// workaround for the API as enum allowing only 'en-us' for English and 'es' for Spanish 🤷‍♂️
const mapLangToApiLang = (lang: LocaleKey) => {
  switch (true) {
    case lang.startsWith('en'):
      return 'en-us'
    case lang.startsWith('es'):
      return 'es'
    case lang.startsWith('fr'):
      return 'fr'
  }
}

const buildInvitations = async (invitations: Invitation[]) => {
  const result = [] as Invitation[]

  for await (const invitation of invitations) {
    if (invitation.status.value === INVITE_STATUS.ACCEPTED) {
      const requirements = await getOrganizationRequirements(invitation.organization)
      result.push({
        ...invitation,
        hasPendingRequirements: requirements.some(requirement => requirement.isPending),
      })
      continue
    }

    // TODO: Remove once API provide TOS links in the response
    if (invitation.status.value === MEMBERSHIP_STATUS.INVITATION_SENT) {
      const { data } = await getOrganizationPublicData(invitation.organization.id)
      invitation.organization.terms_of_use = data.terms_of_use
      invitation.organization.privacy_policy = data.privacy_policy
    }

    result.push(invitation)
  }

  return result
}

export const usePersonalStore = defineStore('personal', {
  state: () => ({
    profile: {} as Profile,
    addressBook: [] as AddressBookType[],
    addressBookTotal: 0,
    isAddressBookFetched: false,
    invitations: [] as Invitation[],
    unansweredInvitations: [],
    invitationsTotal: 0,
    joinedBanner: null as JoinedBanner | null,
    preloadedInvitation: null as Invitation | null,
    scansMetrics: null as ScanMetricsResponse | null,
    averageMetrics: null as AverageMetricsResponse | null,
  }),
  actions: {
    profileSet(profile: Profile) {
      this.profile = profile
    },
    async profileFetch() {
      const resp = await account.profileGet()
      this.profileSet(resp.data)
    },

    async profileUpdate(form: Partial<Profile>) {
      if (form.date_of_birth === '') {
        form.date_of_birth = null
      }
      if (form.language) {
        form.language = mapLangToApiLang(form.language as LocaleKey)
      }
      const resp = await account.profileUpdate(form)
      this.profileSet({ ...this.profile, ...resp.data })
    },

    async profileDelete(form: DeleteByMethodBody) {
      try {
        await account.profileDelete(form)
      } catch (e) {
        console.error(e)
        throw e
      }
    },

    async palmsDelete(form: DeleteByMethodBody) {
      try {
        await account.palmDelete(form)
        await this.profileFetch()
      } catch (e) {
        console.error(e)
        throw e
      }
    },

    async changePassword(form: CodeBody) {
      await account.changePassword(form)
    },
    async setEmail({ code, email, client_id }: EmailSetBody) {
      const resp = await account.setEmail({
        code,
        email,
        client_id,
      })

      this.profileSet({ ...this.profile, ...resp.data.user })
    },
    async setPhone({ code, phone, client_id }: PhoneSetBody) {
      const resp = await account.setPhone({
        code,
        phone,
        client_id,
      })

      this.profileSet({ ...this.profile, ...resp.data.user })
    },
    async changeEmail(form: ChangeEmailBody) {
      try {
        const resp = await account.changeEmail(form)
        this.profileSet({ ...this.profile, ...resp.data.user })
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async changePhone(form: ChangePhoneBody) {
      try {
        const resp = await account.changePhone(form)
        this.profileSet({ ...this.profile, ...resp.data.user })
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async verifyMethod(form: VerifyMethodBody) {
      try {
        await account.verifyMethod(form)
        await this.profileFetch()
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async profileUpdatePhoto(photo: ProfilePhoto) {
      try {
        const resp = await account.profileSetPhoto(photo)
        this.profileSet({ ...this.profile, ...resp.data })
        toast.show(() => i18n.global.t('modules.personal.store.uploadPhotoSuccess'), 'success')
      } catch (e) {
        console.error(e)
        toast.show(() => i18n.global.t('modules.personal.store.uploadPhotoError'), 'error')
      }
    },

    async profileDeletePhoto() {
      try {
        const resp = await account.profileSetPhoto('')
        this.profileSet({ ...this.profile, ...resp.data })
        toast.show(() => i18n.global.t('common.photoDeleted'), 'success')
      } catch (e) {
        console.error(e)
        toast.show(() => i18n.global.t('modules.personal.store.deletePhotoError'), 'error')
      }
    },
    async getAddressBook() {
      try {
        const resp = await account.fetchAddressBook()
        this.addressBook = resp.data.results
        this.addressBookTotal = resp.data.count
        this.isAddressBookFetched = true
      } catch (e) {
        console.error(e)
        toast.show('Failed to fetch address book', 'error')
      }
    },
    async getAddressBookById(id: number) {
      try {
        const { data } = await account.fetchAddressBookById(id)
        return data
      } catch (e) {
        console.error(e)
        toast.show(`Failed fetching address book by ${id}`, 'error')
      }
    },
    async getInvitations() {
      try {
        const resp = await checkInvitations()
        this.invitationsTotal = resp.data.count
        this.unansweredInvitations = resp.data.results.filter(
          (item: Invitation) => item?.status?.value === MEMBERSHIP_STATUS.INVITATION_SENT,
        )
        this.invitations = await buildInvitations(resp.data.results as Invitation[])
      } catch (e) {
        console.error(e)
        toast.show('Failed to fetch invitations', 'error')
      }
    },
    async patchInvitations(invitation_id: number, status: number) {
      try {
        await updateInvitation(invitation_id, status)
        const orgs = useOrganizationsStore()
        orgs.getList()
        this.getInvitations()
      } catch (e) {
        console.error(e)
        toast.show('Failed to respond for invitation', 'error')
      }
    },
    async addJoinedBanner(organizationId: number) {
      const orgs = useOrganizationsStore()
      const orgId = String(organizationId)

      if (!orgs.items.has(orgId)) {
        await orgs.fetchById(orgId)
      }

      const org = orgs.items.get(orgId)

      if (org) {
        this.joinedBanner = {
          organizationId,
          organizationName: org.displayName,
        }
      }
    },
    clearJoinedBanner() {
      this.joinedBanner = null
    },
    async preloadInvitationRequirements({
      invitationId,
      organizationId,
    }: {
      invitationId?: number
      organizationId?: number
    }) {
      try {
        let hasPendingRequirements = false
        // Sign up → Invitation is accepted before getting here
        // Log in → Invitation should be accepted here

        // If invitationId is provided, accept the invitation right away (Usually sign in flow)
        if (invitationId) {
          await this.patchInvitations(invitationId, INVITE_ACTIONS.ACCEPT)
        }

        await this.getInvitations()

        // If invitationId is provided, use it to find the invitation to be more exact,
        // otherwise use organizationId to find the invitation
        const invitation = this.invitations.find(
          (item: Invitation) => item.id === invitationId || item.organization.id === organizationId,
        )

        if (!invitation) {
          return
        }

        // If an invitation for some reason has not been accepted, accept it
        if (invitation && invitation.status.value === MEMBERSHIP_STATUS.INVITATION_SENT) {
          await this.patchInvitations(invitation.id, INVITE_ACTIONS.ACCEPT)
        }

        // Check if organization has pending requirements to avoid re-fetching requirements
        if (invitation.hasPendingRequirements) {
          hasPendingRequirements = true
        } else {
          // Since invitation is accepted after fetching invitations,
          // we need to recheck if requirements are pending again
          const organizationRequirements = await getOrganizationRequirements(
            invitation.organization,
          )
          hasPendingRequirements = organizationRequirements.some(
            requirement => requirement.isPending,
          )
        }

        // If it does not have pending requirements, show joined banner
        if (!hasPendingRequirements) {
          await this.addJoinedBanner(invitation.organization.id)
          return
        }

        // If it has pending requirements, preload invitation data
        this.preloadedInvitation = invitation
      } catch (error) {
        console.error(error)
        toast.show(i18nUtils.errorSomethingBroken, 'error')
      }
    },
    async createAddressBook(payload: Partial<AddressBookType>) {
      try {
        await account.createAddressBook(payload)
        await this.getAddressBook()
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async editAddressBook(id: number, payload: Partial<AddressBookType>) {
      try {
        await account.updateAddressBookById(id, payload)
        await this.getAddressBook()
      } catch (e) {
        console.error(e)
        throw e
      }
    },

    async deleteAddressBook(id: number) {
      try {
        await account.deleteAddressBookById(id)
        await this.getAddressBook()
      } catch (e) {
        console.error(e)
        throw e
      }
    },

    async fetchAverageMetrics() {
      try {
        const { data } = await metricsApi.user.getAverageMetrics()
        this.averageMetrics = data
      } catch (e) {
        console.error(e)
        toast.show(i18nUtils.errorSomethingBroken, 'error')
      }
    },

    async fetchScanMetrics() {
      try {
        const { data } = await metricsApi.user.getScanMetrics()
        this.scansMetrics = data
      } catch (e) {
        console.error(e)
        toast.show(i18nUtils.errorSomethingBroken, 'error')
      }
    },
  },

  getters: {
    addresses(state): AddressBookType[] {
      return state.addressBook.map(address => {
        const country = CountriesService.getCountryByCode(address.country as CountryCodeType)
        return {
          ...address,
          countryName: country?.name() || '',
        }
      })
    },
    firstName(state) {
      return state.profile?.first_name || '-'
    },
    lastName(state) {
      return state.profile?.last_name || '-'
    },
    displayName(state) {
      return state.profile?.display_name || '-'
    },
    someName(state) {
      return (
        state.profile?.display_name ||
        state.profile?.first_name ||
        state.profile?.email?.split('@')[0]
      )
    },
    email(state) {
      return state.profile?.email || '-'
    },
    phone(state) {
      return state.profile?.phone || '-'
    },
    country(state) {
      return state.profile?.country || '-'
    },
    isProfileSet(state) {
      return !!Object.keys(state.profile).length
    },
    initials(state) {
      const inits = `${state.profile?.first_name?.[0] || ''}${state.profile?.last_name?.[0] || ''}`
      return inits || state.profile?.email?.[0] || ''
    },
    fullName(state) {
      const fname = state.profile?.first_name ? state.profile?.first_name : ''
      const lname = state.profile?.last_name ? state.profile?.last_name : ''
      return (fname + ' ' + lname).trim()
    },
    dob(state) {
      return formatDob(state.profile.date_of_birth)
    },
    id(state) {
      return state.profile.id
    },
    photo(state) {
      return state.profile.photo
    },
    isProfileComplete(state) {
      for (let i = 0; i < profileCompleteFields.length; i++) {
        if (!state.profile[profileCompleteFields[i]]) return false
      }
      return true
    },
    isEnrolled() {
      return false
    },
    isOwner(state) {
      return !!state.profile?.owner_org_ids?.length
    },
    hasPermCreateOrganization(state) {
      return state.profile?.permissions?.includes('add_organization')
    },

    canCreateOrganization(state): boolean {
      return state.profile?.owner_org_ids?.length < this.maxOrgs && this.hasPermCreateOrganization
    },
    maxOrgs(state) {
      return state.profile?.org_limit
    },
    acceptedAt(state) {
      const [, month, day, year] = new Date(state.profile.date_joined).toDateString().split(' ')
      return `${month} ${day}, ${year}`
    },
    lastEnrollmentDate(state) {
      const { currentLocale } = useLanguage()
      return toLocaleString(state.profile?.last_enrollment_date, currentLocale.value)
    },
    lastIdentificationDate(state) {
      const { currentLocale } = useLanguage()
      return toLocaleString(state.profile?.last_identification_date, currentLocale.value)
    },
    lastDeletedBiometricDate(state) {
      const { currentLocale } = useLanguage()
      return toLocaleString(state.profile?.last_deleted_biometric_date, currentLocale.value)
    },
    preferableMfaMethod(state): SignInMethod {
      return state.profile?.preferable_mfa_method ?? 'email'
    },
    preferableMfaMethodName(state) {
      return getMethodI18nValue(state.profile?.preferable_mfa_method)
    },
    preferableMfaMethodValue(state): string {
      return (
        state.profile?.preferable_mfa_method === 'phone'
          ? state.profile?.phone
          : state.profile?.email
      ) as string
    },
  },
})
