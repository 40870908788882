import { useRoute } from 'vue-router'
import { useOrganizationsStore } from '../store/organizations'
import { useMembersStore } from '@/modules/members/pinia'
import { useDevicesStore } from '../store/devices'
import { computed, watch } from 'vue'
import type { Organization } from '@/modules/organization/types/model.ts'
import type { Profile } from '@/modules/account/pinia'

// basic hierarchy power
const roles: Record<string, number> = {
  'Owner': 3,
  'Admin': 2,
  'Device Manager': 1,
  'User': 0,
}

let isWatchingOrgChanges = false

export function useCurrentRouteEntities() {
  const route = useRoute()
  const members = useMembersStore()
  const organizations = useOrganizationsStore()
  const devices = useDevicesStore()

  const member = computed<Profile | undefined>(() =>
    members.items.get(String(route.params.memberId)),
  )
  const organization = computed<Organization>(
    () => organizations.items.get(String(route.params.id)) as Organization,
  )
  const device = computed(() => devices.items.get(String(route.params.deviceId)))

  if (!isWatchingOrgChanges) {
    isWatchingOrgChanges = true
    watch(
      () => route.params.id,
      id => id && organizations.fetchById(id as string),
    )
  }

  const isAuthnUserOwner = computed(() => {
    if (!organization.value) return false
    return Number(organization.value.owner.member_id) === Number(organization.value.member.id)
  })

  const isAdminOrOwner = computed(() => {
    if (!organization.value) return false
    return roles[organization.value.member.role] >= roles['Admin']
  })

  /**
   * Checks permission by role hierarchy
   * @param {Object} [m] - organization member. default to current route member
   * @returns {Boolean}
   */
  const canEditMember = (m = member.value) => {
    const loggedInMember = organization.value?.member
    if (!m || !loggedInMember?.role || !roles[loggedInMember.role]) return false
    return (
      isAdminOrOwner.value &&
      roles[loggedInMember.role] >= roles[m.role] &&
      loggedInMember.id !== m.id
    )
  }

  return { organization, member, device, canEditMember, isAuthnUserOwner, isAdminOrOwner }
}
