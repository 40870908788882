import { defineAsyncComponent, markRaw } from 'vue'
import type {
  MappedRequirement,
  OrgRequirements,
  Location,
} from '@/modules/organization/types/model.ts'
import { getMembershipById } from '@/api/organization'

import walletApi from '@/modules/wallet/api'
import { i18n } from '@keyo/core/i18n'
import type { InvitationOrganization } from '@/modules/account/pinia'
import { useOrganizationsStore } from '@/store'

export type DeviceOrganizationData = {
  id: number
  location: Location
  organization: {
    id: number
    name: string
    business_name: string
    logo: string
  }
}

export const getOrganizationRequirements = async (
  organization: InvitationOrganization,
): Promise<MappedRequirement[]> => {
  const result: MappedRequirement[] = []
  const requirements: OrgRequirements[] = []

  if (
    organization.settings?.is_required_address &&
    import.meta.env.APP_ENABLE_ADDRESS_REQUIREMENT === 'true'
  ) {
    requirements.push('address')
  }

  if (organization.payment_provider_ids.length) {
    requirements.push('payment_provider')
  }

  if (!requirements.length) return []

  for await (const requirement of requirements) {
    if (!requirement) continue
    const preparedRequirement = await prepareRequirement(requirement, organization.id)

    if (preparedRequirement) {
      result.push(preparedRequirement)
    }
  }

  return result
}

const prepareRequirement = async (
  requirement: OrgRequirements,
  organizationId: number,
): Promise<MappedRequirement | null> => {
  switch (requirement) {
    case 'payment_provider': {
      const { data } = await walletApi.fetchPaymentMethodsByOrgId(organizationId)

      return {
        component: markRaw(
          defineAsyncComponent(
            () =>
              import(
                '@/modules/organization/modals/OrganizationInvitationRequirements/requirements/PaymentProvider.vue'
              ),
          ),
        ),
        name: i18n.global.t('modules.organization.requirements.connectMobileWallet'),
        value: 'payment_provider',
        icon: 'wallet',
        isPending: !data.count,
      }
    }
    case 'address': {
      let isPending = false
      const organizationStore = useOrganizationsStore()

      const organization = organizationStore.items.get(String(organizationId))

      if (!organization) {
        await organizationStore.fetchById(organizationId)
      }

      const membership = organizationStore.items.get(String(organizationId))?.member

      if (membership) {
        const { data } = await getMembershipById(organizationId, membership.id)
        isPending = !data.address
      }

      return {
        component: markRaw(
          defineAsyncComponent(
            () =>
              import(
                '@/modules/organization/modals/OrganizationInvitationRequirements/requirements/AddressRequirement.vue'
              ),
          ),
        ),
        name: i18n.global.t('Add your address'),
        value: 'address',
        icon: 'location',
        isPending,
      }
    }
    default:
      return null
  }
}
