import type { defineAsyncComponent } from 'vue'
import type { CountryCodeType } from '@keyo/core'
import type { IconType } from '@keyo/ui'

export const MEMBERSHIP_STATUS = {
  INVITATION_SENT: 0,
  ACTIVE: 1,
  PAUSED: 2,
  DEACTIVATED: 3,
} as const

export type MEMBERSHIP_STATUS_VALUE = (typeof MEMBERSHIP_STATUS)[keyof typeof MEMBERSHIP_STATUS]
export type MEMBERSHIP_STATUS_NAME = keyof typeof MEMBERSHIP_STATUS

export type RoleType = 'Owner' | 'Admin' | 'User' | 'Device Manager'

export interface Country {
  code: string
  name: string
}

export interface Role {
  name: RoleType
  id: number
}

export interface Status {
  value: number
  name: string
}

export interface Location {
  country: Country
  city: string
  street: string
  street_address_line: string
  zip_code: string
  id: number
  state: string
}

export interface Membership {
  role: RoleType
  id: number
  status: Status
}

export interface AuthKey {
  secret_creation_date: string
  token: string
}

export interface DeviceType {
  value: string
  name: string
}

export interface LocationPhoto {
  photo: string
  position: number | null
  creation_date: string
  update_date: string
  id: number
  photo_thumbnail_small: string
  photo_thumbnail_medium: string
  photo_thumbnail_large: string
}

export interface PaymentProviderCorrespondent {
  correspondent: string
  currency: string
  ownerName: string
  operationTypes: {
    operationType: string
    minTransactionLimit: string
    maxTransactionLimit: string
  }[]
}

export interface PaymentProviderMetadata {
  merchantId: string
  merchantName: string
  countries: {
    country: string
    correspondents: PaymentProviderCorrespondent[]
  }[]
}

export interface PaymentProvider {
  id: number
  name: string
  is_active: boolean
  is_required: boolean
  provider: string
  metadata: PaymentProviderMetadata
  fee_percent: string
}

export type OrgRequirements = 'payment_provider' | 'address'

export type MappedRequirement = {
  component: ReturnType<typeof defineAsyncComponent>
  name: string
  value: OrgRequirements
  icon: IconType
  isPending: boolean
}

export interface OrganizationSettings {
  is_required_address: boolean
  is_joined_org_enabled: boolean
  organization_join_link?: string
}

export interface PublicOrganization {
  id: number
  description: string
  email: string
  phone_number: string
  website_url: string
  background: string
  privacy_policy: string
  terms_of_use: string
  public_name: string
  public_contact: Contact
  country: Country
  cropped_square_logo: string
  policies: string[]
}

export interface Organization extends PublicOrganization {
  name: string
  displayName: string
  is_active: boolean
  business_email: string
  dba: string
  business_contact_first_name: string
  business_contact_last_name: string
  update_date: string
  creation_date: string
  initials: string
  localization: {
    language: string
    country: Record<string, any>
    timezone: string
  }

  billing_address: Address
  address: Address
  shipping_address: Address

  primary_contact: Contact
  billing_contact: Contact | null

  roles: Role[]
  business_phone: string
  logo: string
  tin: string
  member: Membership

  total_user_count: number
  owner: Record<string, any>

  logo_thumbnail_small: string
  logo_thumbnail_medium: string

  auth_keys: AuthKey[]
  payment_provider: PaymentProvider
  payment_provider_ids: number[]

  settings: OrganizationSettings

  use_primary_contact: boolean
  billing_address_same_as_address: boolean

  hasIntegrations: boolean
}

export interface Address {
  country: {
    name: string
    code: string | CountryCodeType
  }
  city: string
  street: string
  street_address_line: string
  zip_code: string
  id: number
  state: string
}

export interface Contact {
  first_name: string
  last_name: string
  email: string
  phone_number: string
  website_url: string
}

export interface DeviceManager {
  membership_id: number
  manager_photo?: string
  full_name: string
  id: number
  initials?: string
}

export interface Device {
  name: string
  mac_address: string | null
  mac_address_terminal: string | null
  ip_address: string | null
  last_up_time_since: string | null
  last_down_time_since: string | null
  update_date: string
  creation_date: string
  serial_number: string
  location: Location
  id: number
  location_photos: LocationPhoto[]
  device_type: DeviceType
  uuid_id: string
  auth_keys: AuthKey[]
  managers: DeviceManager[]
}
