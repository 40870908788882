<script setup lang="ts">
import { inject, reactive, ref } from 'vue'

import { DialogBtn, TextField } from '@keyo/ui'
import type { AxiosError } from 'axios'

import useInputSize from '@/composables/useInputSize'

import accountApi from '../api'
import NonFieldErrors from '@/modules/auth/components/NonFieldErrors.vue'
import useFormHelpers from '@/composables/useFormHelpers'
import { external, email, required } from '@keyo/core/validations'
import { useVuelidate } from '@vuelidate/core'
import { type Captcha, captchaInjectionKey } from '@keyo/core'
import ModalCard from '@/components/modals/components/ModalCard.vue'
import { useModal } from '@/composables/useModal'

export type Form = {
  email: string
  captcha_token: string
}

const captcha = inject(captchaInjectionKey) as Captcha

const isSubmitting = ref(false)

const modal = useModal()
const size = useInputSize()
const { handleResponseException } = useFormHelpers()

const form = reactive<Form>({
  email: '',
  captcha_token: '',
})

type Errors = { [key: string]: string } & Partial<typeof form & { non_field_errors: string }>

const externalResults = reactive<Errors>({})

const rules = {
  email: [required(), email()],
  captcha_token: [external],
}

const v$ = useVuelidate(rules, form, {
  $externalResults: externalResults,
  $autoDirty: true,
  $rewardEarly: true,
})

const submit = async () => {
  if (isSubmitting.value) return
  isSubmitting.value = true
  await v$.value.$validate()
  if (v$.value.$error) {
    isSubmitting.value = false
    return
  }

  try {
    await captcha.execute()

    form.captcha_token = captcha.token.value
    const { data } = await accountApi.mfaCodeRequest({
      method: 'email',
      email: form.email,
      action: 'confirm_email',
      captcha_token: form.captcha_token,
    })

    modal.show('AccountEmailAddStep2', {
      isCustomStyle: true,
      email: form.email,
      clientId: data.client_id,
    })
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  } finally {
    isSubmitting.value = false
  }
}
</script>

<template>
  <ModalCard
    tag="form"
    size="m"
    icon="email-ground"
    icon-color="lavender"
    :heading="$t('Add email')"
    :description="$t('modules.account.modals.accountEmailStep1.toAddEnterEmail')"
    @submit.prevent="submit"
  >
    <TextField
      id="email"
      v-model="form.email"
      :size="size"
      :disabled="isSubmitting"
      :placeholder="$t('Email')"
      :state="v$.email.$error ? 'error' : ''"
      :tip="v$.email.$errors?.[0]?.$message"
    />

    <NonFieldErrors :errors="externalResults.captcha_token" />
    <NonFieldErrors :errors="externalResults.non_field_errors" />

    <template #buttons>
      <DialogBtn kind="secondary" @click="modal.hide">
        {{ $t('buttons.cancel') }}
      </DialogBtn>
      <DialogBtn :loading="isSubmitting" type="submit">
        {{ $t('buttons.continue') }}
      </DialogBtn>
    </template>
  </ModalCard>
</template>
