<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useLanguage } from '@/composables/useLanguage'
import { Icon, Badge } from '@keyo/ui'
import { onClickOutside } from '@vueuse/core'

const isDropdownOpen = ref(false)
const languageSwitcherMenuRef = ref(null)

const dropdownMenuClass = computed(() => ({
  'navigation-dropdown--open': isDropdownOpen.value,
}))

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value
}

onClickOutside(languageSwitcherMenuRef, () => {
  isDropdownOpen.value = false
})

const { selectedLang, updateLocale, saveLocaleInUserSettings, languages, isBetaLang } =
  useLanguage()
const handleLocaleChange = (locale: string) => {
  updateLocale(locale)
  saveLocaleInUserSettings(locale)
}
</script>

<template>
  <div
    ref="languageSwitcherMenuRef"
    class="navigation-dropdown"
    :class="dropdownMenuClass"
    role="menu"
  >
    <button class="navigation-button" @click="toggleDropdown">
      <Icon name="translate" />
    </button>
    <ul v-show="isDropdownOpen" class="navigation-list">
      <li v-for="{ locale, displayName, isBeta } in languages" :key="locale" role="menuitem">
        <button
          class="language-switcher-button"
          :class="{
            'language-switcher-button--selected': selectedLang.locale === locale,
          }"
          @click="handleLocaleChange(locale), toggleDropdown()"
        >
          <!-- Locale and lang are rendered by css based on collapsed state -->
          <!-- to avoid errors moving between resolutions -->
          <span
            class="language-switcher-button-name text-label-s"
            :data-locale="locale"
            :data-lang="displayName"
          >
            {{ displayName }}
            <Badge v-if="isBeta" color="blue" class="beta-badge"> {{ $t('Beta') }} </Badge>
          </span>
          <Icon v-if="selectedLang.locale === locale" name="checkmark" class="icon-check" />
        </button>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.navigation-dropdown {
  position: relative;
}

.navigation-button {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: var(--clr-bg-secondary);
  color: var(--clr-icon-subtle);

  &:hover {
    background: var(--clr-bg-tertiary);
  }
}

.navigation-list {
  display: grid;
  position: absolute;
  top: 3rem;
  box-shadow: 0px 0px 12px -3px rgba(66, 66, 73, 0.1);
  right: 0;
  min-width: 8rem;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--clr-border-default);
  background: var(--clr-bg-primary);
  padding: 0.25rem;
}

.language-switcher-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 0.75rem 0.5rem;
  border-radius: 0.5rem;

  &.is-focused,
  &:hover {
    background: var(--clr-bg-secondary);
  }

  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 1px var(--clr-border-focus);
  }

  &:active {
    background: var(--clr-bg-tertiary);
  }

  .language-switcher-button-name {
    position: relative;
    display: flex;
    gap: 0.5rem;
    align-items: center;

    // eslint-disable-next-line vue-scoped-css/no-unused-selector
    .badge {
      min-width: fit-content;
      font: var(--text-body-xs);
      padding-inline: 0.3rem;
    }
  }
}
.icon-check {
  width: 1rem;
  height: 1rem;
  color: var(--clr-icon-subtle);
}
</style>
