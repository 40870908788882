<script setup lang="ts">
import type { PropType } from 'vue'
import { inject, onMounted, reactive, ref } from 'vue'
import { DialogBtn, Pin } from '@keyo/ui'
import { BtnResend } from '@/components/BtnResend'
import NonFieldErrors from '@/modules/auth/components/NonFieldErrors.vue'
import ModalCard from '@/components/modals/components/ModalCard.vue'

import accountApi from '../api/'

import { useModal } from '@/composables/useModal'
import { usePersonalStore, type ChangeEmailBody } from '../pinia'
import useFormHelpers from '@/composables/useFormHelpers.ts'
import { mfaCode, required, external } from '@keyo/core/validations'
import { useVuelidate } from '@vuelidate/core'
import type { AxiosError } from 'axios'
import type { Code } from '@/types/code.ts'
import { type Captcha, captchaInjectionKey } from '@keyo/core'

const captcha = inject(captchaInjectionKey) as Captcha

const props = defineProps({
  form: {
    type: Object as PropType<ChangeEmailBody>,
    required: true,
  },
})

const modal = useModal()
const personal = usePersonalStore()
const { handleResponseException } = useFormHelpers()

const form = reactive<ChangeEmailBody>({
  ...props.form,
  code_from_new_email: [],
  client_id_from_new_email: '',
})

type Errors = { [key: string]: string } & Partial<ChangeEmailBody & { non_field_errors: string }>
const externalResults = reactive<Errors>({})
const rules = {
  code_from_new_email: [required(), mfaCode()],
  client_id_from_new_email: [external],
}

const v$ = useVuelidate(
  rules,
  form as {
    code_from_new_email: Code
    client_id_from_new_email: string
  },
  {
    $externalResults: externalResults,
    $autoDirty: true,
    $rewardEarly: true,
  },
)

const isSubmitting = ref(false)

async function sendCode() {
  try {
    await captcha.execute()
    const { data } = await accountApi.mfaCodeRequest({
      method: 'email',
      email: form.new_email as string,
      action: 'confirm_email',
      captcha_token: captcha.token.value,
    })
    form.client_id_from_new_email = data.client_id
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  }
}

async function submit() {
  if (isSubmitting.value) return
  isSubmitting.value = true
  await v$.value.$validate()
  if (v$.value.$error) {
    isSubmitting.value = false
    return
  }
  try {
    // change email not an atomic operation, so we need to validate it first
    // https://linear.app/keyoid/issue/API-515/change-email-invalid-code-from-new-email-invalidates-code-from-old
    await accountApi.validateChangeEmail(form)
    await personal.changeEmail(form as ChangeEmailBody)
    modal.show('account-email-change-success', { isCustomStyle: true })
  } catch (error) {
    console.error(error)
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  } finally {
    isSubmitting.value = false
  }
}

onMounted(sendCode)
</script>

<template>
  <ModalCard
    tag="form"
    icon="email"
    icon-color="lavender"
    size="m"
    :heading="$t('modules.account.modals.accountEmailChangeStep3.heading')"
    :description="
      $t('modules.account.modals.accountEmailChangeStep3.description', {
        newEmail: props.form.new_email,
      })
    "
    @submit.prevent="submit"
  >
    <Pin
      class="pin"
      :state="v$.code_from_new_email.$error ? 'error' : ''"
      :tip="v$.code_from_new_email.$errors?.[0]?.$message"
      @input="form.code_from_new_email = $event"
    />
    <NonFieldErrors :errors="externalResults.non_field_errors" />
    <BtnResend @click="sendCode" />
    <template #buttons>
      <DialogBtn kind="secondary" @click="modal.hide">
        {{ $t('buttons.cancel') }}
      </DialogBtn>
      <DialogBtn :loading="isSubmitting" type="submit">
        {{ $t('buttons.continue') }}
      </DialogBtn>
    </template>
  </ModalCard>
</template>

<style scoped>
.pin {
  justify-self: center;
}
</style>
