// Option to work only with single org. We should ALWAYS flush this store on organization switch
import { defineStore } from 'pinia'
import { toast } from '@keyo/ui'
import {
  bulkDeleteMembership,
  getMembershipById,
  getMemberships,
  updateMembership,
} from '@/api/organization'
import * as invites from '../api/invites'
import * as roles from '../api/roles'

import { prepareUser } from '@/api/utils'
import { DEFAULT_LIMIT } from '@/constants/pagination'
import { useOrganizationsStore } from '@/store'
import type { OwnerTransferBody } from '../api/roles'
import type { InviteBody } from '../api/invites'
import type { AddressBookType, Profile, UserStatus } from '@/modules/account/pinia'

// usersById {[id]: user}
// list: []

interface State {
  items: Map<string, Profile>
  total: number
  managers: Profile[]
  isItemsFetched: boolean
  isManagersFetched: boolean
}

type MemberResponse = {
  user: Profile
  date_joined: string
  member_id: number
  status: UserStatus
  metadata: Record<string, string>
  address: AddressBookType
  organization_role: string
}

export type UpdateMembershipRoleBody = {
  organization_role?: string | number
  status?: 0 | 1 | 2 | 3 | undefined
}
export const useMembersStore = defineStore('members', {
  state: (): State => ({
    items: new Map(),
    total: 0,
    managers: [],
    isManagersFetched: false, // flag to check if managers already was fetched to not block render.
    isItemsFetched: false, // flag to check if items already was fetched to not block render.
  }),
  actions: {
    async invite(orgId: string | number, body: InviteBody) {
      await invites.invite(orgId, body)
      await this.fetchList(String(orgId))
    },
    async fetchList(id: string, search = '', page = 1, limit = DEFAULT_LIMIT) {
      try {
        const resp = await getMemberships(id, {
          offset: limit * (page - 1),
          limit,
          search,
        })
        const members = resp?.data?.results ?? []

        this.$patch(s => {
          const nextItems = new Map()
          members.forEach((m: MemberResponse) => nextItems.set(`${m.member_id}`, prepareUser(m)))
          s.items = nextItems
          s.total = resp.data.count
          s.isItemsFetched = true
        })
      } catch (e) {
        toast.show("Users not found or you don't have permission", 'error')
        this.router.push('/personal')
        console.error(e)
        throw e
      }
    },

    async fetchById(orgId: string | number, memberId: string | number) {
      try {
        const userAssignTo = this.items.get(String(memberId))
        const resp = await getMembershipById(orgId, memberId)
        const data = prepareUser(resp.data)
        userAssignTo ? Object.assign(userAssignTo, data) : this.items.set(String(memberId), data)
      } catch (e) {
        toast.show("User not found or you don't have permission", 'error')
        this.router.push('/personal')
        console.error(e)
        throw e
      }
    },

    async pauseMembership(orgId: string | number, memberId: string | number) {
      const resp = await updateMembership(orgId, memberId, { status: 2 })
      this.items.set(`${memberId}`, prepareUser(resp.data))
    },

    async renewMembership(orgId: string | number, memberId: string | number) {
      const resp = await updateMembership(orgId, memberId, { status: 1 })
      this.items.set(`${memberId}`, prepareUser(resp.data))
    },
    async updateMembershipRole(
      orgId: string | number,
      memberId: string | number,
      body: UpdateMembershipRoleBody,
    ) {
      const resp = await updateMembership(orgId, memberId, body)
      this.items.set(`${memberId}`, prepareUser(resp.data))
    },
    async fetchManagers(orgId: string | number, search = '') {
      const resp = await getMemberships(orgId, {
        role: 'Device Manager',
        search,
        limit: 5000, // TODO: Possible pagination if it scales
      })

      this.managers = resp.data.results.map(prepareUser)
      this.isManagersFetched = true
    },
    async remove(orgId: string | number, memberIds: string[] | number[]) {
      const resp = await bulkDeleteMembership(orgId, memberIds)
      if (resp.status === 204) {
        this.$patch(s => {
          memberIds.forEach(id => s.items.delete(`${id}`))
          s.total -= memberIds.length
        })
      }
    },
    async transferOwnership(orgId: string | number, body: OwnerTransferBody) {
      const organizations = useOrganizationsStore()

      try {
        const resp = await roles.ownerTransfer(orgId, body)
        const memberId = String(resp.data.member_id)
        const userAssignTo = this.items.get(memberId)
        const data = prepareUser(resp.data)
        userAssignTo ? Object.assign(userAssignTo, data) : this.items.set(memberId, data)

        await Promise.all([
          this.fetchById(orgId, body.ownerId as string),
          organizations.fetchById(orgId),
        ])
      } catch (e) {
        console.error(e)
        throw e
      }
    },
  },
})
