import FileSaver from 'file-saver'
import { apiV3, apiV4 } from '@/api'
// Organization
export const createOrganization = body => {
  const url = '/organizations/'
  return apiV3.post(url, body)
}

export const getOwnOrganizationsList = (limit = 50, offset = 0) => {
  const url = `/organizations/?limit=${limit}&offset=${offset}`
  return apiV3.get(url)
}

export const getOrganizationById = id => {
  const url = `/organizations/${id}/`
  return apiV4.get(url)
}

export const getOrganizationPublicData = id => {
  const url = `/public/organizations/${id}/`
  return apiV4.get(url)
}

export const getOrganizationSettings = id => {
  return apiV4.get(`/organizations/${id}/settings/`)
}

export const updateOrganizationById = (id, data) => {
  const url = `/organizations/${id}/`
  return apiV4.patch(url, { ...data })
}

export const updateOrganizationPublicSettings = (id, data) => {
  const url = `/organizations/${id}/update-settings/`
  // TODO: remove image fields, refactor to multipart/form-data once ready https://linear.app/keyoid/issue/BCKEN-978/add-multipartform-data-support-for-the-organizations-public-profile
  const { background, logo, ...body } = data
  return apiV4.patch(url, body)
}

/**
 * @param {*} id Organization id
 * @param {Object} assets Assets to update: supports logo and background
 * @param {File | ''} [assets.logo]
 * @param {File | ''} [assets.background]
 */
export const updateOrganizationAssets = (id, assets) => {
  const url = `/organizations/${id}/upload-logo/`
  const formData = new FormData()
  if (assets.logo !== undefined) {
    formData.append('logo', assets.logo)
  }
  if (assets.background !== undefined) {
    formData.append('background', assets.background)
  }
  return apiV3.patch(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

// Users(members)
export const inviteUserToOrganization = (orgId, body) => {
  const url = `/organizations/${orgId}/invite-user/`
  return apiV3.post(url, body)
}

export const checkInvitations = () => {
  const url = '/organizations/invitations/'
  return apiV3.get(url)
}

export const updateInvitation = (invitationId, status) => {
  const url = `/organizations/invitations/${invitationId}/`
  return apiV3.patch(url, { status })
}

export const getMemberships = (orgId, params) => {
  const query = new URLSearchParams(params).toString()
  const url = `/organizations/${orgId}/members/?${query}`
  return apiV3.get(url)
}

export const getMembershipById = (orgId, membershipId) => {
  const url = `/organizations/${orgId}/members/${membershipId}/`
  return apiV3.get(url)
}

export const updateOrganizationMembership = (orgId, membershipId, payload) => {
  const url = `/organizations/${orgId}/membership/${membershipId}/`
  return apiV4.patch(url, payload)
}

// export const membershipSearch = (orgId, search) => {
//   const url = `/organizations/${orgId}/members/?search=${search}`
//   return apiV3.get(url)
// }

export const updateMembership = (orgId, membershipId, body) => {
  const url = `/organizations/${orgId}/members/${membershipId}/`
  return apiV3.patch(url, body)
}

export const bulkDeleteMembership = (orgId, ids) => {
  const url = `/organizations/${orgId}/members/bulk-delete/`
  return apiV3.post(url, { ids })
}

export const exportMembersToCsv = async orgId => {
  const { data: dataForCsv } = await apiV3.get(`/organizations/${orgId}/membership-reports/list/`)
  const currentDate = new Date()
  const blob = new Blob([dataForCsv], { type: 'text/csv;charset=utf-8' })
  const fileName = `members-${currentDate.toLocaleDateString()}-${currentDate.toLocaleTimeString()}.csv`
  FileSaver.saveAs(blob, fileName)
}

// Devices
export const getOrganizationDevicesList = (id, params) => {
  const query = new URLSearchParams(params).toString()
  const url = `/organizations/${id}/devices/?${query}`
  return apiV3.get(url)
}

export const getDeviceProfile = (orgId, deviceId) => {
  const url = `/organizations/${orgId}/devices/${deviceId}/`
  return apiV3.get(url)
}

export const getDeviceProfileExposedData = (orgId, deviceId) => {
  const url = `/organizations/${orgId}/devices/${deviceId}/exposed-data/`
  return apiV3.get(url)
}

export const patchDeviceProfile = (orgId, deviceId, body) => {
  const url = `/organizations/${orgId}/devices/${deviceId}/`
  return apiV3.patch(url, body)
}

export const createDeviceLocationPhoto = (orgId, deviceId, photo) => {
  const url = `/organizations/${orgId}/devices/${deviceId}/location-photos/`
  const formData = new FormData()
  formData.append('photo', photo)
  return apiV3.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const deleteDeviceLocationPhoto = (orgId, deviceId, photoId) => {
  const url = `/organizations/${orgId}/devices/${deviceId}/location-photos/${photoId}/`
  return apiV3.delete(url, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const organizationLeave = orgId => {
  return apiV3.delete(`organizations/${orgId}/leave/`)
}

export const startEnroll = (orgId, deviceId) => {
  return apiV3.post('devices/create-start-enroll/', { organization_id: orgId, device_id: deviceId })
}

export const addManagersToDevices = (orgId, device_ids, membership_ids) =>
  apiV4.post(`organizations/${orgId}/device-managers/`, {
    membership_ids,
    device_ids,
  })

export const deleteDeviceManager = (orgId, managerId) =>
  apiV4.delete(`organizations/${orgId}/device-managers/${managerId}/`)
