import type { RoleType } from '@/modules/organization/types/model.ts'
import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/organizations/:id/members',
    meta: {
      requiresOrgAccess: true,
    },
    children: [
      {
        path: '',
        name: 'org.members',
        meta: {
          orgAllowedRoles: ['Admin', 'Owner'] satisfies RoleType[],
        },
        component: () => import('@/modules/organization/pages/users/MemberList.vue'),
      },
      {
        path: '/organizations/:id/members/:memberId',
        component: () => import('@/modules/organization/pages/users/Member.vue'),
        children: [
          {
            path: '',
            name: 'org.member',
            component: () => import('@/modules/organization/pages/users/MemberGeneral.vue'),
          },
        ],
      },
    ],
  },
]
export default routes
