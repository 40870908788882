import type { RoleType } from '@/modules/organization/types/model.ts'

export default [
  {
    path: '/organizations/:id/dev-tools',
    name: 'org.dev-tools',
    redirect: { name: 'org.dev-tools.webhooks' },
    meta: {
      requiresOrgAccess: true,
      orgAllowedRoles: ['Admin', 'Owner'] satisfies RoleType[],
    },
    children: [
      {
        path: '/organizations/:id/dev-tools/webhooks',
        name: 'org.dev-tools.webhooks',
        component: () => import('@/modules/webhooks/pages/Webhooks.vue'),
      },
      {
        path: '/organizations/:id/dev-tools/api-credentials',
        name: 'org.dev-tools.api-creds',
        component: () => import('@/modules/api-creds/pages/ApiCreds.vue'),
      },
      {
        path: '/organizations/:id/dev-tools/activity-emulator',
        name: 'org.dev-tools.emulator',
        component: () => import('@/modules/demo-ui/pages/SystemActivityEmulator.vue'),
      },
    ],
  },
]
