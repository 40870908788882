import { markRaw, ref, type Component, watch } from 'vue'
import { useAuthN } from '@/composables/useAuthN'

interface DrawerProps {
  [key: string]: any
}
interface DrawerState {
  component: Component
  props?: DrawerProps
}

export const _drawerState = ref<DrawerState | null>()

export default function () {
  const { isAuthenticated } = useAuthN()

  const open = (component: Component, props?: DrawerProps) => {
    _drawerState.value = {
      component: markRaw(component),
      props,
    }
  }

  const close = () => {
    _drawerState.value = null
  }

  watch(isAuthenticated, async isAuth => {
    if (!isAuth) {
      close()
    }
  })

  return {
    open,
    show: open,
    close,
    hide: close,
  }
}
