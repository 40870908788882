import { defineStore } from 'pinia'
import { toast } from '@keyo/ui'

import { usePersonalStore } from '@/modules/account/pinia'
import {
  createOrganization,
  getMemberships,
  getOrganizationById,
  getOwnOrganizationsList,
  organizationLeave,
  updateOrganizationById,
  updateOrganizationAssets,
  updateOrganizationPublicSettings,
  getOrganizationPublicData,
  getOrganizationSettings,
} from '@/api/organization'
import type { TotalScansByApp } from '@/modules/metrics/api/metrics'
import metricsApi from '@/modules/metrics/api/metrics'
import { i18nUtils } from '@keyo/core/i18n'
import { MEMBERSHIP_STATUS } from '@/modules/organization/types/model'
import type { Organization, Role, RoleType, Status } from '@/modules/organization/types/model'

type UpdateAssets = {
  logo?: File | ''
  background?: File | ''
}

type PrepareOrganizationData = Organization & {
  membership?: {
    organization_role: Role
    status: Status
    id: number
  }
  organization_roles: Role[]
}

export type CreateOrganizationBody = {
  name: string
  dba: string
  tin: string
  shipping_address: {
    country: string
    state: string
    city: string
    zip_code: string
    street: string
  }
  business_phone: string
}

const allowedRoles: RoleType[] = ['Owner', 'Admin', 'User', 'Device Manager']

const prepareOrg = (data: PrepareOrganizationData): Organization => {
  const { membership, organization_roles: roles, ...org } = data

  if (roles) {
    org.roles = roles.filter(role => allowedRoles.includes(role.name))
  }

  if (org.name) {
    org.displayName = org.dba || org.name
    org.initials = org.displayName[0]
  }

  if (org.payment_provider_ids) {
    org.hasIntegrations = org.payment_provider_ids.length > 0
  }

  if (membership) {
    org.member = {
      role: membership.organization_role.name,
      status: membership.status,
      id: membership.id,
    }
  }

  if (org.billing_address?.country === null) {
    org.billing_address.country = { code: '', name: '' }
  }

  return org
}

function setItem(this: State, payload: Organization) {
  const id = String(payload.id)
  const assignTo = this.items.get(id)
  const prepared = prepareOrg(payload as PrepareOrganizationData)
  assignTo ? Object.assign(assignTo, prepared) : this.items.set(id, prepared)
}

interface State {
  items: Map<string, Organization>
  publicItems: Map<string, Organization>
  order: string[]
  scansMetricsByOrg: Map<string, any>
  averageMetricsByOrg: Map<string, any>
  activeMembersCountLastMonth: Map<string, number>
  activeMembersCount: Map<string, number>
  total: number
  isItemsFetched: boolean
  successfulScansByApp: Map<string, TotalScansByApp>
}

export const useOrganizationsStore = defineStore('organizations', {
  state: (): State => ({
    items: new Map<string, Organization>(),
    publicItems: new Map<string, Organization>(),
    order: [] as string[],
    successfulScansByApp: new Map(),
    scansMetricsByOrg: new Map<string, any>(),
    averageMetricsByOrg: new Map<string, any>(),
    activeMembersCountLastMonth: new Map<string, number>(),
    activeMembersCount: new Map<string, number>(),
    total: 0,
    isItemsFetched: false,
  }),
  actions: {
    setItem,
    async create(body: CreateOrganizationBody) {
      await createOrganization(body)
      await this.getList()

      const personal = usePersonalStore()
      await personal.profileFetch()
    },

    async getList() {
      const resp = await getOwnOrganizationsList()
      this.$patch(state => {
        resp.data.results.forEach((org: Organization) => setItem.call(state, org))
      })
    },

    async fetchById(id: number | string, options: { redirect?: boolean } = { redirect: true }) {
      try {
        // TODO: required to merge default and public profile. missing from API: `email`
        const [all, pub, settings] = await Promise.all([
          getOrganizationById(id),
          getOrganizationPublicData(id),
          getOrganizationSettings(id),
        ])

        this.setItem(Object.assign(all.data, pub.data, settings.data))
      } catch (e) {
        toast.show("Organization not found or you don't have permission", 'error')
        options?.redirect && this.router.push('/personal')
        console.error(e)
        throw e
      }
    },

    async fetchPublicDataById(id: number | string) {
      try {
        const { data } = await getOrganizationPublicData(id)
        this.publicItems.set(String(id), data)
      } catch (e) {
        console.error(e)
        toast.show(i18nUtils.errorSomethingBroken, 'error')
      }
    },

    async update(id: number | string, body: Partial<Organization>) {
      const resp = await updateOrganizationById(id, body)
      this.setItem(resp.data)
    },

    async updatePublicSettings(id: number | string, body: Partial<Organization>) {
      const resp = await updateOrganizationPublicSettings(id, body)
      this.setItem(resp.data)
    },

    async updateAssets(id: number | string, assets: UpdateAssets) {
      const resp = await updateOrganizationAssets(id, assets)
      this.setItem(resp.data)
    },

    async leave(orgId: number | string) {
      try {
        await organizationLeave(orgId)
        this.items.delete(`${orgId}`)
      } catch (error) {
        console.error(error)
      }
    },

    async fetchAverageMetricsByOrg(orgId: number | string) {
      try {
        const { data } = await metricsApi.organization.getAverageMetrics(orgId as number)
        this.averageMetricsByOrg.set(String(orgId), data)
      } catch (e) {
        console.error(e)
        toast.show(i18nUtils.errorSomethingBroken, 'error')
      }
    },

    async fetchScanMetricsByOrg(orgId: number | string) {
      try {
        const { data } = await metricsApi.organization.getScanMetrics(orgId as number)
        this.scansMetricsByOrg.set(String(orgId), data)
      } catch (e) {
        console.error(e)
        toast.show(i18nUtils.errorSomethingBroken, 'error')
      }
    },

    // TODO: Move this to metrics module in its own pinia store
    async fetchSuccessfulScans(orgId: number | string) {
      try {
        const { data } = await metricsApi.organization.getTotalScansByApp(orgId as number, {
          status: 'success',
        })
        this.successfulScansByApp.set(String(orgId), data)
      } catch (e) {
        console.error(e)
        toast.show(i18nUtils.errorSomethingBroken, 'error')
      }
    },

    async fetchActiveMembersCount(orgId: number | string) {
      try {
        const { data } = await getMemberships(orgId, {
          offset: 0,
          limit: 1,
          status: MEMBERSHIP_STATUS.ACTIVE,
        })
        this.activeMembersCount.set(String(orgId), data.count)
      } catch (e) {
        console.error(e)
        toast.show(i18nUtils.errorSomethingBroken, 'error')
      }
    },

    async fetchActiveMembersCountLastMonth(orgId: number | string): Promise<void> {
      try {
        const now = new Date()

        const formatUTCDate = (date: Date): string => date.toISOString().split('T')[0] // YYYY-MM-DD

        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1) // First day of the current month
        const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1) // First day of the next month

        const { data } = await getMemberships(orgId, {
          offset: 0,
          limit: 1, // We only need the count
          date_joined__gte: formatUTCDate(startOfMonth),
          date_joined__lt: formatUTCDate(endOfMonth),
          status: MEMBERSHIP_STATUS.ACTIVE,
        })

        this.activeMembersCountLastMonth.set(String(orgId), data.count)
      } catch (e) {
        console.error(e)
        toast.show(i18nUtils.errorSomethingBroken, 'error')
      }
    },
  },
  getters: {
    organizations: (state): Organization[] => Array.from(state.items.values()),

    spaces: (state: State): Organization[] =>
      Array.from(state.items.values()).filter(
        org => org.member?.role !== 'User' && org.member?.status.value === MEMBERSHIP_STATUS.ACTIVE,
      ),

    networks: (state: State): Organization[] =>
      Array.from(state.items.values()).filter(
        org => org.member?.role === 'User' && org.member?.status.value === MEMBERSHIP_STATUS.ACTIVE,
      ),

    withPayments: (state: State): Organization[] =>
      Array.from(state.items.values()).filter(org => org.payment_provider_ids.length > 0),
  },
})
