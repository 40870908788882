export default {
  'Assigned managers': 'Gestionnaires attribués',
  'Add manager': 'Ajouter un gestionnaire',
  'Model': 'Modèle',
  'SN': 'NS',
  'Assignee': 'Assigné',
  'Select manager': 'Sélectionner un gestionnaire',
  'Assign manager': 'Attribuer un gestionnaire',
  'Location view': 'Vue de l’emplacement',
  'No managers assigned': 'Aucun gestionnaire attribué',
  'Managers currently assigned to this device':
    'Gestionnaires actuellement attribués à ce périphérique.',
  'Provide a name to easily identify the device':
    'Fournissez un nom pour identifier facilement le périphérique.',
  'Unexpected error during updating device profile':
    'Erreur inattendue lors de la mise à jour du profil du périphérique',
  'Provide the official address of the device': 'Fournissez l’adresse officielle du périphérique.',
  'Confirm removal': 'Confirmer la suppression',
  'wouldYouLikeToRemoveMemberAsAssignedManagerOfDevice':
    'Souhaitez-vous supprimer {memberName} en tant que gestionnaire attribué de {deviceName}?',
  'Photos of the terminal’s area to show its location':
    'Photos de la zone du terminal pour montrer son emplacement.',
  'Assign a manager to this device': 'Attribuer un gestionnaire à ce périphérique.',
  'usersHaveBeenSuccessfullyAssigned':
    '{count} a été attribué avec succès au périphérique. | {count} ont été attribués avec succès au périphérique.',
  'No managers available to assign': 'Aucun gestionnaire disponible pour attribuer',
  'There are currently no managers you can assign. Check back later or update the manager list':
    "Il n'y a actuellement aucun gestionnaire que vous pouvez attribuer. Revenez plus tard ou mettez à jour la liste des gestionnaires.",
  'Managers will appear here once they’ve been assigned':
    'Les gestionnaires apparaîtront ici une fois qu’ils auront été attribués.',
}
