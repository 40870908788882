// Option to work only with single org. We should ALWAYS flush this store on organization switch
import { defineStore } from 'pinia'
import {
  addManagersToDevices,
  createDeviceLocationPhoto,
  deleteDeviceLocationPhoto,
  deleteDeviceManager,
  getDeviceProfile,
  getOrganizationDevicesList,
  patchDeviceProfile,
  startEnroll,
} from '../api/organization'
import { toast } from '@keyo/ui'
import { DEFAULT_LIMIT } from '@/constants/pagination'
import type { Device } from '@/modules/organization/types/model.ts'

interface ListOptions {
  search?: string
  page?: number
  limit?: number
}
const prepareDevice = (device: Device): Device => {
  device.managers = device.managers.map(m => ({
    ...m,
    initials: m.full_name
      .split(' ')
      .slice(0, 2)
      .map(n => n[0])
      .join(''),
  }))

  return device
}

export const useDevicesStore = defineStore('devices', {
  state: () => ({
    items: new Map<string, Device>(),
    total: 0,
    isItemsFetched: false,
  }),
  actions: {
    async fetchList(orgId: number, options: ListOptions = {}) {
      try {
        const { search, page, limit } = {
          search: options.search || '',
          page: options.page || 1,
          limit: options.limit || DEFAULT_LIMIT,
        }

        const resp = await getOrganizationDevicesList(orgId, {
          offset: limit * (page - 1),
          limit: limit,
          search: search,
        })

        const devices = resp?.data?.results ?? []
        this.$patch(state => {
          const nextItems = new Map()
          devices.forEach((d: Device) => nextItems.set(`${d.id}`, prepareDevice(d)))
          state.items = nextItems
          state.total = resp.data.count
          state.isItemsFetched = true
        })
      } catch (e) {
        toast.show("Devices not found or you don't have permission", 'error')
        this.router.push('/personal')
        console.error(e)
        throw e
      }
    },

    async fetchById(orgId: number, deviceId: number, options = { redirect: true }) {
      try {
        const resp = await getDeviceProfile(orgId, deviceId)
        const data = prepareDevice(resp.data)
        this.items.set(`${deviceId}`, data) // Convert to string key to match Map type
      } catch (e) {
        // TODO: Find a better approach to address not found devices
        toast.show("Device not found or you don't have permission", 'error')
        options?.redirect && this.router.push({ name: 'org' })
        console.error(e)
        throw e
      }
    },

    async update(orgId: number, deviceId: number, body: Partial<Device>) {
      const resp = await patchDeviceProfile(orgId, deviceId, body)
      this.items.set(`${resp.data.id}`, resp.data)
    },

    async startEnroll(orgId: number, deviceId: number) {
      const resp = await startEnroll(orgId, deviceId)
      this.items.set(`${resp.data.id}`, resp.data)
    },

    async uploadPhoto(orgId: number, deviceId: number, file: File) {
      await createDeviceLocationPhoto(orgId, deviceId, file)
      await this.fetchById(orgId, deviceId)
    },

    async deletePhoto(orgId: number, deviceId: number, photoId: number) {
      await deleteDeviceLocationPhoto(orgId, deviceId, photoId)
      await this.fetchById(orgId, deviceId)
    },

    async removeDeviceManager(orgId: number, deviceId: number, managerId: number) {
      await deleteDeviceManager(orgId, managerId)
      await this.fetchById(orgId, deviceId)
    },

    async createManagersToDevice(orgId: number, deviceId: number, managerIds: number[]) {
      await addManagersToDevices(orgId, [deviceId], managerIds)
      await this.fetchById(orgId, deviceId)
    },
  },
})
