import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { KEYO_WEB } from '@/constants/urls'

export type POLICIES = 'terms-of-use' | 'privacy-policy' | 'biometric-policy' | 'messaging-policy'

export default function usePoliciesUrl() {
  const { locale } = useI18n()

  // TODO: remove `el` once policies in Greek available at marketing-site
  const isDefault = computed(() => ['en', 'el'].includes(locale.value))

  const computedUrl = (policyUrl: POLICIES) =>
    computed(() =>
      isDefault.value ? `${KEYO_WEB}/${policyUrl}` : `${KEYO_WEB}/${locale.value}/${policyUrl}`,
    )

  const termsOfUse = computedUrl('terms-of-use')
  const privacyPolicy = computedUrl('privacy-policy')
  const biometricPolicy = computedUrl('biometric-policy')
  const messagingPolicy = computedUrl('messaging-policy')

  return {
    termsOfUse,
    privacyPolicy,
    biometricPolicy,
    messagingPolicy,
  }
}
