import { defineStore } from 'pinia'
import webhooks from '../api'
import type { Webhook, WebhookBody } from '../model'

const handleWebhookItem = (item: Webhook) => ({
  createdDate: `${new Date(item.creation_date).toLocaleDateString()}`,
  createdTime: `${new Date(item.creation_date).toLocaleTimeString()}`,

  isActive: item.status.name === 'ACTIVE',
  isSecretSet: item.is_secret_token_existing,
  ...item,
})

type StateWebhook = Webhook & {
  createdDate: string
  createdTime: string
  isActive: boolean
  isSecretSet: boolean
}

type State = {
  items: StateWebhook[]
  byId: Record<number, StateWebhook>
  isItemsFetched: boolean
}

export default defineStore('webhooks', {
  state: (): State => ({
    items: [],
    byId: {},
    isItemsFetched: false, // flag to check if items already was fetched to not block render.
  }),
  actions: {
    async create(orgId: string | number, body: WebhookBody) {
      try {
        await webhooks.create(orgId, body)
        this.fetchList(orgId)
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async fetchList(orgId: string | number) {
      try {
        const resp = await webhooks.list(orgId)
        this.$patch(state => {
          state.items = resp.data.results.map(handleWebhookItem)
          state.items.forEach((itm, i) => {
            state.byId[itm.id] = itm
          })
          state.isItemsFetched = true
        })
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async disable(orgId: string | number, id: string | number) {
      try {
        const { data } = await webhooks.update(orgId, id, { status: 0 })
        Object.assign(this.byId[data.id], handleWebhookItem(data))
      } catch (e) {
        console.error(e)
        throw e
      }
    },

    async update(orgId: string | number, id: string | number, body: WebhookBody) {
      try {
        const { data } = await webhooks.update(orgId, id, body)
        Object.assign(this.byId[data.id], handleWebhookItem(data))
      } catch (e) {
        console.error(e)
        throw e
      }
    },

    async enable(orgId: string | number, id: string | number) {
      try {
        const { data } = await webhooks.update(orgId, id, { status: 1 })
        Object.assign(this.byId[data.id], handleWebhookItem(data))
      } catch (e) {
        console.error(e)
        throw e
      }
    },

    async delete(orgId: string | number, id: string | number) {
      try {
        await webhooks.delete(orgId, id)
        const i = this.items.findIndex(itm => itm.id === Number(id))
        this.$patch(state => {
          i !== -1 && state.items.splice(i, 1)
          delete state.byId[Number(id)]
        })
      } catch (e) {
        console.error(e)
        throw e
      }
    },
  },
})
