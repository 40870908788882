<script setup lang="ts">
import { useModal } from '@/composables/useModal'
import PhoneAdd from '@/modules/account/components/PhoneAdd.vue'
import type { PhoneAddSubmit } from '@/modules/account/components/PhoneAdd.vue'

const modal = useModal()

const props = defineProps({
  heading: {
    type: String,
  },
  description: {
    type: String,
  },
  onSuccess: {
    type: Function,
  },
})

const handleSubmit = async ({ phone, clientId }: PhoneAddSubmit) => {
  modal.show('account-phone-add-step2', {
    isCustomStyle: true,
    phone,
    clientId,
    onSuccess: props.onSuccess,
  })
}
</script>

<template>
  <PhoneAdd
    icon="call-phone"
    :heading="heading"
    :description="description"
    @finished="handleSubmit"
    @canceled="modal.close()"
  />
</template>
