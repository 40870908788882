<script lang="ts" setup>
import { DialogBtn } from '@keyo/ui'
import ModalCard from '@/components/modals/components/ModalCard.vue'

import { useModal } from '@/composables/useModal'

defineProps<{
  name: string
}>()

const modal = useModal()
</script>

<template>
  <ModalCard
    icon="error-circle"
    icon-color="red"
    :heading="$t('common.hiName', { name })"
    :description="$t('modules.account.modals.accountBiometricAlreadyExists.description')"
    :with-mobile-header-style="false"
  >
    <template #buttons>
      <DialogBtn @click="modal.hide">{{ $t('buttons.gotIt') }}</DialogBtn>
    </template>
  </ModalCard>
</template>
