export default {
  'Enable webhook?': 'Activer le webhook?',
  'Enable': 'Activer',
  'enablingWebhookStartEvents':
    'En activant ce point de terminaison webhook, il commencera à recevoir des notifications sur les événements.',
  'Disable webhook?': 'Désactiver le webhook ?',
  'Disable': 'Désactiver',
  'disablingWebhookStopEvents':
    "En désactivant temporairement ce point de terminaison du webhook, il cessera de recevoir des notifications d'événements.",
  'Remove webhook?': 'Supprimer le webhook ?',
  'removingWebhookNoEventSent':
    "La suppression de ce webhook signifie qu'aucun message webhook ne sera envoyé lorsque cet événement se produit au sein de votre organisation.",
  'Add endpoint': 'Ajouter un point de terminaison',
  'Endpoint URL': 'URL du point de terminaison',
  'Secret': 'Secret',
  'Select events': 'Sélectionner les événements',
  'Add': 'Ajouter',
  'Edit endpoint': 'Modifier le point de terminaison',
  'Save': 'Sauvegarder',
  'The webhook endpoint was saved successfully':
    'Le point de terminaison du webhook a été enregistré avec succès.',
  'The webhook endpoint was added successfully':
    'Le point de terminaison du webhook a été ajouté avec succès.',
  'Failed to retrieve webhook secret': 'Échec de la récupération du secret du webhook.',
  'URL': 'URL',
  'Events': 'Événements',
  'Status': 'Statut',
  'Created': 'Créé',
  'Set': 'Défini',
  'Missing': 'Manquant',
  'Active': 'Actif',
  'Disabled': 'Désactivé',
  'Edit webhook': 'Modifier le webhook',
  'Disable webhook': 'Désactiver le webhook',
  'Enable webhook': 'Activer le webhook',
  'Remove webhook': 'Supprimer le webhook',
  'No webhooks added': 'Aucun webhook ajouté',
  'toGetStartedClick':
    'Pour commencer, cliquez sur “Ajouter un point de terminaison” pour en ajouter un.',
}
