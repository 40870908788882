<script setup lang="ts">
import type { PropType } from 'vue'
import { inject, defineAsyncComponent, computed, reactive, ref } from 'vue'

import type { Icon } from '@keyo/ui'
import { DialogBtn, PhoneField } from '@keyo/ui'
import type { AxiosError } from 'axios'

import useInputSize from '@/composables/useInputSize'

import accountApi from '../api'
import NonFieldErrors from '@/modules/auth/components/NonFieldErrors.vue'
import useFormHelpers from '@/composables/useFormHelpers'
import { i18n } from '@keyo/core/i18n'
import { phone, required } from '@keyo/core/validations'
import { useVuelidate } from '@vuelidate/core'
import { useI18n } from 'vue-i18n'
import { type Captcha, captchaInjectionKey } from '@keyo/core'

export type PhoneAddSubmit = {
  clientId: string
  phone: string
}

export type Form = {
  phone: string
}

const props = defineProps({
  icon: {
    type: String as PropType<typeof Icon.props.name>,
  },
  kind: {
    type: String as PropType<'modalCard' | 'authCard'>,
    default: () => 'modalCard',
  },
  heading: {
    type: String,
  },
  description: {
    type: String,
  },
  primaryButton: {
    type: String,
  },
  secondaryButton: {
    type: String,
  },
})

const captcha = inject(captchaInjectionKey) as Captcha

const { t } = useI18n()

const headingText = computed(() => props.heading ?? t('common.addPhoneNumber'))

const descriptionText = computed(
  () => props.description ?? t('modules.account.components.phoneAdd.toAddEnterPhoneNumber'),
)

const primaryButtonText = computed(() => props.primaryButton ?? i18n.global.t('buttons.continue'))
const secondaryButtonText = computed(() => props.secondaryButton ?? i18n.global.t('buttons.cancel'))

const emit = defineEmits(['finished', 'canceled'])

const isSubmitting = ref(false)

const size = useInputSize()
const { handleResponseException } = useFormHelpers()

const form = reactive<Form>({
  phone: '',
})

type Errors = { [key: string]: string } & Partial<typeof form & { non_field_errors: string }>

const externalResults = reactive<Errors>({})

const rules = {
  phone: [required(), phone()],
}

const v$ = useVuelidate(rules, form, {
  $externalResults: externalResults,
  $autoDirty: true,
  $rewardEarly: true,
})

const submit = async () => {
  if (isSubmitting.value) return
  isSubmitting.value = true
  await v$.value.$validate()
  if (v$.value.$error) {
    isSubmitting.value = false
    return
  }

  try {
    await captcha.execute()
    const { data } = await accountApi.mfaCodeRequest({
      method: 'phone',
      phone: form.phone,
      action: 'confirm_phone',
      captcha_token: captcha.token.value,
    })
    const clientId = data.client_id
    emit('finished', {
      clientId,
      phone: form.phone,
    } satisfies PhoneAddSubmit)
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  } finally {
    isSubmitting.value = false
  }
}

const component = computed(() => {
  switch (props.kind) {
    case 'authCard':
      return defineAsyncComponent(() => import('@/modules/auth/components/AuthCard.vue'))
    case 'modalCard':
    default:
      return defineAsyncComponent(() => import('@/components/modals/components/ModalCard.vue'))
  }
})
</script>

<template>
  <component
    :is="component"
    tag="form"
    size="m"
    :icon="icon"
    icon-color="lavender"
    :heading="headingText"
    :description="descriptionText"
    @submit.prevent="submit"
  >
    <PhoneField
      id="phone"
      v-model="form.phone"
      :size="size"
      :disabled="isSubmitting"
      :placeholder="$t('Phone number')"
      :state="v$.phone.$error ? 'error' : ''"
      :tip="v$.phone.$errors?.[0]?.$message"
    />

    <NonFieldErrors :errors="externalResults.non_field_errors" />

    <template #buttons>
      <DialogBtn
        class="cancel-button"
        kind="secondary"
        :disabled="isSubmitting"
        @click="$emit('canceled')"
      >
        {{ secondaryButtonText }}
      </DialogBtn>
      <DialogBtn type="submit" :disabled="!form.phone" :loading="isSubmitting">
        {{ primaryButtonText }}
      </DialogBtn>
    </template>
  </component>
</template>
