export const KEYO_WEB = 'https://keyo.com'

export const POSTMAN = 'https://postman.keyo.co/'

export const API_REFERENCE = import.meta.env.APP_API_REF

export const ARTICLE_PRIVACY_BY_DESIGN = 'https://medium.com/@tam_keyo/66d3953de7f1'
export const ARTICLE_WHY_KEYO =
  'https://medium.com/onlykeyo/why-youll-love-being-a-keyo-member-9eb9a25e3139'
export const BUSINESSES = 'https://keyo.com/businesses'

export const CONTACT_US = 'https://keyo.com/contact-us'
export const FEATURE_REQUEST = 'https://keyo.canny.io/feature-requests'
export const FEEDBACK_FORM =
  'https://support.keyo.com/hc/en-us/requests/new?ticket_form_id=16679879395085'

export const DEV_PORTAL = 'https://developers.keyo.co'
