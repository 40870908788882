<script setup lang="ts">
/**
 * This component is designed for scenarios where users need to switch their MFA method,
 * particularly useful in user settings or security settings pages.
 * It's adaptable to various UI contexts by supporting different card layouts (`modalCard`, `dialogCard`, `authCard`)
 */
import { computed, defineAsyncComponent, nextTick, reactive } from 'vue'
import { DialogBtn } from '@keyo/ui'
import { usePersonalStore } from '@/modules/account/pinia'
import MfaMethodItem from '@/modules/account/components/MfaMethodItem.vue'
import type { SignInMethod } from '@/api/auth.ts'
import { useModal } from '@/composables/useModal'
import { modalState } from '@/composables/useModal.ts'

interface Props {
  method?: SignInMethod
  email?: string
  phone?: string
  canEdit?: boolean
  kind?: 'modalCard' | 'dialogCard' | 'authCard'
  processing?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  kind: 'modalCard', // Choose `dialogCard` or `authCard` for different UI contexts, like dialogs or authentication flows.
})

defineEmits(['changed', 'canceled', 'onEdit'])

const modal = useModal()
const personalStore = usePersonalStore()

// Reactive data for the component, with default values and logic to handle verification states.
// Uses the user's existing data from the store as defaults
const data = reactive({
  email: props.email ?? personalStore.profile.email,
  phone: props.phone ?? personalStore.profile.phone,
  method: props.method ?? personalStore.preferableMfaMethod, // Prefers user's existing MFA method as default
})

// TODO: We could refactor dialogCard Component to use different styles based on the kind prop inside UI package
const component = computed(() => {
  switch (props.kind) {
    case 'modalCard':
      return defineAsyncComponent(() => import('@/components/modals/components/ModalCard.vue'))
    case 'dialogCard':
      return defineAsyncComponent(() => import('@keyo/ui'))
    case 'authCard':
      return defineAsyncComponent(() => import('@/modules/auth/components/AuthCard.vue'))
  }
})

// Recover the last modal state if available, crucial for maintaining context during multi-step verification processes.
const recoverLastModalIfExists = (state: typeof modalState.value, method?: SignInMethod) => {
  if (state && state.component) {
    modal.show(state.component, method ? { ...state.props, method } : state.props, state.slots)
  }
}

const verifyMethod = (method: SignInMethod) => {
  const currentModalState = modalState.value
  modal.show('AccountVerifyMethod', {
    isCustomStyle: true,
    method,
    finished: async () => {
      await nextTick()
      recoverLastModalIfExists(currentModalState, method)
    },
    onHide: async () => {
      await nextTick()
      recoverLastModalIfExists(currentModalState)
    },
  })
}
</script>

<template>
  <component
    :is="component"
    tag="form"
    size="m"
    :heading="$t('modules.auth.common.changeVerificationMethod')"
    @submit.prevent="$emit('changed', data.method)"
    @back="$emit('canceled')"
  >
    <template #description>
      {{ $t('modules.personal.components.cardChangeMfaMethod.ifHaveNotReceivedCode') }}
    </template>

    <ul class="mfa-methods">
      <MfaMethodItem
        v-if="data.email"
        v-model="data.method"
        method="email"
        :verify="!email && !personalStore.profile.is_confirmed_email"
        :label="data.email"
        @start-verification="verifyMethod('email')"
      />

      <MfaMethodItem
        v-if="data.phone"
        v-model="data.method"
        method="phone"
        :verify="!phone && !personalStore.profile.is_confirmed_phone"
        :label="data.phone"
        @start-verification="verifyMethod('phone')"
      />
    </ul>

    <template #buttons>
      <DialogBtn class="cancel-button" kind="secondary" @click="$emit('canceled')">
        {{ $t('buttons.cancel') }}
      </DialogBtn>
      <DialogBtn type="submit" :loading="processing">
        {{ $t('buttons.continue') }}
      </DialogBtn>
    </template>
  </component>
</template>

<style lang="scss" scoped>
.mfa-methods {
  :deep(.mfa-method):first-child {
    border-bottom: 1px solid var(--color-grey-200);
  }
}

@media screen and (min-width: $mobile) {
  .mfa-methods {
    max-width: 34rem;
    :deep(.mfa-method):first-child {
      border-bottom: none;
    }
  }
}
</style>
