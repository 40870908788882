import { apiV3 } from '@/api'
const ensureHttpsPrefix = url => {
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`
  }
  return url
}

export default {
  create(orgId, body) {
    return apiV3.post(`organizations/${orgId}/webhooks/`, {
      ...body,
      url: ensureHttpsPrefix(body.url),
    })
  },
  list(orgId) {
    return apiV3.get(`organizations/${orgId}/webhooks/`)
  },
  update(orgId, id, body) {
    return apiV3.patch(`organizations/${orgId}/webhooks/${id}/`, {
      ...body,
      url: body.url ? ensureHttpsPrefix(body.url) : undefined,
    })
  },
  delete(orgId, id) {
    return apiV3.delete(`organizations/${orgId}/webhooks/${id}/`)
  },
  getSecret(orgId, id) {
    return apiV3.get(`organizations/${orgId}/webhooks/${id}/creds/`)
  },
}
