import { computed, ref, watch } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
import type {
  SignInMethod,
  SignUpBody,
  SignUpBaseBody,
  ActivateByInvitationBody,
} from '../api/auth.ts'
import {
  activateUserByInvitation,
  authSignUpValidate,
  authSignUp,
  authLogin,
  authRegister,
  authSignInMfa,
} from '../api/auth.ts'

import { usePersonalStore } from '@/modules/account/pinia'
import { isAuthRoute } from '@/pages/routes'
import type { Profile } from '@/modules/account/pinia'
import { censorEmail, censorString } from '@/utils/stringUtils'
import organizationApi from '@/modules/organization/api'
import type { FieldErrors } from '@keyo/core/validations'
import { fieldErrorProcessor } from '@keyo/core/validations'
import { toast } from '@keyo/ui'
import type { AxiosError } from 'axios'
import type { ErrorObject } from '@vuelidate/core'
import * as storeUtils from '@/store/utils'
import { i18n } from '@keyo/core/i18n'
import router from '@/pages/router.ts'

export type OrganizationOrInvitation = {
  organizationId?: number
  invitationId?: string
}

// Function to check if error is Axios error
export function isAxiosError(error: any): error is AxiosError {
  return error?.isAxiosError === true
}

// Dirty workaround to handle code resend, sending creds to get new code
// API should provide proper functionality instead
const storedCredentials = ref<{
  emailOrPhone?: string
  email?: string
  phone?: string
  password?: string
  code?: string
  client_id?: string
  method?: SignInMethod
  first_name?: string
  last_name?: string
  captcha_token?: string
}>({})

// API should provide in response censored email or phone instead
const censoredAuthData = computed<{
  emailOrPhone?: string
  email?: string
  phone?: string
}>(() => {
  if (storedCredentials.value?.emailOrPhone) {
    const emailOrPhone = storedCredentials.value?.emailOrPhone
    return {
      emailOrPhone: emailOrPhone.includes('@')
        ? censorEmail(emailOrPhone)
        : censorString(emailOrPhone),
    }
  } else if (storedCredentials.value.email || storedCredentials.value.phone) {
    return {
      ...(storedCredentials.value.email
        ? { email: censorEmail(storedCredentials.value.email) }
        : {}),
      ...(storedCredentials.value.phone
        ? { phone: censorString(storedCredentials.value.phone) }
        : {}),
    }
  }

  return {}
})

let watchStopHandle: () => void
export const isAuthenticated = ref(false)

export function useAuthN() {
  const personal = usePersonalStore()

  const persistentCredsRoutes = [
    'login.code',
    'activate.verify',
    'activate.verify.edit',
    'activate.verify.edit.method',
    'activate.code',
    'register', // to persist registration form when going back from verify email/phone page
  ]

  // TODO: disable all automatic join when API has proper functionality
  // Programmatically join organization after sign up and preload invitation requirements
  const handleJoinOrganization = async (organizationId: number) => {
    const errors = <FieldErrors>{}

    try {
      await organizationApi.joinOrganization(organizationId)
      await personal.preloadInvitationRequirements({
        organizationId: organizationId,
      })
    } catch (e) {
      if (!isAxiosError(e)) throw e
      fieldErrorProcessor.handleFieldsErrors(e.response?.data as ErrorObject, errors)

      // 6311 - user is already a member of this organization
      // allow to proceed with login in case already member of the organization
      if (errors?.['non_field_errors']?.code === 6311) {
        toast.show(i18n.global.t(fieldErrorProcessor.getI18nCode(6311)))
        return
      }
      throw e
    }
  }

  function clearSession() {
    isAuthenticated.value = false
    watchStopHandle()
    clearStoredCredentials()
    storeUtils.resetAll()
  }

  async function signOut() {
    return router.replace({ name: 'login' }).then(clearSession)
  }

  async function handleUserLogin(user: Profile) {
    if (!user) return
    personal.profileSet(user)

    isAuthenticated.value = true

    const nextRoute = personal.isProfileComplete
      ? router.currentRoute.value.query.redirect || {
          name: 'personal',
          query: router.currentRoute.value.query,
        }
      : { name: 'activate.full-name', query: router.currentRoute.value.query }

    return router.replace(nextRoute as string | RouteLocationRaw)
  }

  /**
   * Sign in with email/phone and password
   */
  async function signIn(
    credentials = storedCredentials.value,
    { invitationId, organizationId }: OrganizationOrInvitation = {},
  ) {
    if (!credentials) return

    const methodType = credentials.method === 'email' ? 'email' : 'phone'

    const payload = {
      method: credentials.method as SignInMethod,
      password: credentials.password as string,
      [methodType]: credentials[methodType] as string,
      captcha_token: credentials.captcha_token as string,
    }

    const response = await authLogin(payload)

    if (!response) {
      throw new Error()
    }

    // If a user has MFA enabled, redirect to login code page and handle login there
    if (response.data.is_mfa) {
      storedCredentials.value = { ...response.data, ...credentials }

      const query: Record<string, string> = {
        ...router.currentRoute.value.query,
        b: response.data.client_id,
      }

      if (invitationId) {
        query.invitationId = invitationId
      }

      return router.replace({
        name: 'login.code',
        query,
      })
    }

    if (organizationId) {
      await handleJoinOrganization(organizationId)
    } else if (invitationId) {
      await personal.preloadInvitationRequirements({
        invitationId: Number(invitationId),
      })
    }

    return handleUserLogin(response.data.user)
  }

  async function signInByMfa(
    code: string,
    { invitationId, organizationId }: OrganizationOrInvitation = {},
  ) {
    const resp = await authSignInMfa({
      method: 'email',
      client_id: router.currentRoute.value.query.b as string,
      code,
    })

    if (organizationId) {
      await handleJoinOrganization(organizationId)
    } else if (invitationId) {
      await personal.preloadInvitationRequirements({
        invitationId: Number(invitationId),
      })
    }

    return handleUserLogin(resp.data.user)
  }

  async function signUpValidate(body: SignUpBaseBody) {
    const response = await authSignUpValidate(body)
    storedCredentials.value = {
      ...storedCredentials.value,
      ...body,
    }

    return response
  }

  async function signUpByInvitation(payload: ActivateByInvitationBody) {
    const response = await activateUserByInvitation(payload)

    if (!response) {
      throw new Error()
    }

    await personal.preloadInvitationRequirements({
      organizationId: payload.organization,
    })

    return handleUserLogin(response.data.user)
  }

  async function signUp(code: string, organizationIdToJoin?: number) {
    const { email, phone, first_name, last_name, client_id, password } =
      storedCredentials.value as SignUpBody

    const response = await authSignUp({
      email,
      phone,
      first_name,
      last_name,
      password,
      code,
      client_id,
    } satisfies SignUpBody)

    if (!response) {
      throw new Error()
    }

    if (organizationIdToJoin) {
      await handleJoinOrganization(organizationIdToJoin)
    }

    return handleUserLogin(response.data.user)
  }

  async function register(email: string) {
    return authRegister(email)
  }

  const updateStoredCredentials = (newCredentials: typeof storedCredentials.value) => {
    storedCredentials.value = {
      ...storedCredentials.value,
      ...newCredentials,
    }
  }

  const getStoredCredentials = () => storedCredentials.value

  const clearStoredCredentials = () => {
    storedCredentials.value = {}
  }

  if (!watchStopHandle) {
    watchStopHandle = watch(router.currentRoute, to => {
      if (isAuthRoute(router.currentRoute)) {
        isAuthenticated.value = false
      }

      // Cleanup storedCredentials from memory once user changes page
      if (!persistentCredsRoutes.includes(to.name as string)) {
        clearStoredCredentials()
      }
    })
  }

  return {
    signOut,

    register,
    signInByMfa,

    signIn,

    signUp,
    signUpValidate,

    signUpByInvitation,
    getStoredCredentials,
    clearStoredCredentials,
    updateStoredCredentials,

    clearSession,
    handleUserLogin,

    watchStopHandle,

    censoredAuthData,
    isAuthenticated,
    storedCredentials,
  }
}
