import { nextTick, ref, watch } from 'vue'
import type { h } from 'vue'

import { nanoid } from 'nanoid'

import { useAuthN } from '@/composables/useAuthN'
import type modals from '@/components/modals/modals'

type Slots = Parameters<typeof h>[2]
type CamelCaseKeys = keyof typeof modals

type CamelToKebabCase<S extends string> = S extends `${infer T}${infer U}`
  ? U extends Uncapitalize<U>
    ? `${Lowercase<T>}${CamelToKebabCase<U>}`
    : `${Lowercase<T>}-${CamelToKebabCase<Uncapitalize<U>>}`
  : S

// Allows for either camelCase or kebab-case keys to be used
// Eg.
// show('modal-name') or show('modalName')
type Component = CamelCaseKeys | CamelToKebabCase<CamelCaseKeys>

type Props = Record<string, unknown> & {
  onHide?: () => void
}

export const modalState = ref<{
  component?: Component
  props?: Props
  slots?: Slots
  id: string
}>()

export function useModal() {
  const { isAuthenticated } = useAuthN()

  watch(isAuthenticated, async isAuth => {
    if (!isAuth) {
      await hide()
    }
  })

  async function show(component: Component, props?: Props, slots?: Slots): Promise<void> {
    // Force modal to react to modalState change
    modalState.value = undefined

    await nextTick()

    // Set modalState to new modal
    modalState.value = { component, props, slots, id: nanoid() }
    // adding dummy route to able close modal with browser back button
    // vue router can't be used as it will break onBeforeRouteLeave guard e.g. leave confirmation modal
    history.pushState(history.state, '')
  }

  const hide = async () => {
    if (!modalState.value) return
    modalState.value?.props?.onHide?.()
    modalState.value = undefined
  }

  return { show, hide, close: hide }
}
