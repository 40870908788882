export default {
  'Add': 'Προσθήκη',
  'Add endpoint': 'Προσθήκη τελικού σημείου',
  'Disable': 'Απενεργοποίηση',
  'Disable webhook?': 'Απενεργοποίηση του webhook;',
  'Edit endpoint': 'Επεξεργασία τελικού σημείου',
  'Enable': 'Ενεργοποίηση',
  'Enable webhook?': 'Ενεργοποίηση του webhook;',
  'Endpoint URL': 'Διεύθυνση URL τελικού σημείου',
  'Failed to retrieve webhook secret': 'Αποτυχία ανάκτησης του webhook secret.',
  'Remove webhook?': 'Κατάργηση του webhook;',
  'Save': 'Αποθήκευση',
  'Secret': 'Secret',
  'Select events': 'Επιλογή συμβάντων',
  'The webhook endpoint was added successfully':
    'Το τελικό σημείο του webhook προστέθηκε με επιτυχία.',
  'The webhook endpoint was saved successfully':
    'Το τελικό σημείο του webhook αποθηκεύτηκε με επιτυχία.',
  'disablingWebhookStopEvents':
    'Με την προσωρινή απενεργοποίηση του τελικού σημείου του webhook, θα σταματήσει να λαμβάνει ειδοποιήσεις σχετικά με συμβάντα.',
  'enablingWebhookStartEvents':
    'Ενεργοποιώντας το τελικό σημείο του webhook, θα αρχίσει να λαμβάνει ειδοποιήσεις σχετικά με συμβάντα.',
  'removingWebhookNoEventSent':
    'Η κατάργηση του webhook σημαίνει ότι δεν θα αποστέλλονται μηνύματα webhook κάθε φορά που πραγματοποιείται εκδήλωση στον οργανισμό σας.',
  'URL': 'URL',
  'Events': 'Εκδηλώσεις',
  'Status': 'Κατάσταση',
  'Created': 'Δημιουργήθηκε',
  'Set': 'Ορισμένο',
  'Missing': 'Λείπει',
  'Active': 'Ενεργό',
  'Disabled': 'Απενεργοποιημένο',
  'Edit webhook': 'Επεξεργασία webhook',
  'Disable webhook': 'Απενεργοποίηση webhook',
  'Enable webhook': 'Ενεργοποίηση webhook',
  'Remove webhook': 'Αφαίρεση webhook',
  'No webhooks added': 'Δεν έχουν προστεθεί webhooks',
  'toGetStartedClick':
    'Για να ξεκινήσετε, κάντε κλικ στο “Προσθήκη τελικού σημείου” για να προσθέσετε ένα.',
}
