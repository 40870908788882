<script setup lang="ts">
import { UAParser } from 'ua-parser-js'
import { DialogBtn } from '@keyo/ui'
import ModalCard from '@/components/modals/components/ModalCard.vue'

import { useModal } from '@/composables/useModal'

const modal = useModal()

const osToRouteName = (osName?: string) => {
  const baseName = 'guides.camera-permission'
  if (osName !== 'Android' && osName !== 'iOS') return baseName
  return `${baseName}.${osName.toLowerCase()}`
}
const browserToRouteHash = (browser?: string) => {
  if (browser) return '#' + browser.split(' ').join('-').toLowerCase()
  return '#app-permission'
}

const ua = UAParser(navigator.userAgent)
const guideRoute = {
  name: osToRouteName(ua.os.name),
  hash: browserToRouteHash(ua.browser.name),
}
</script>

<template>
  <ModalCard
    icon="camera"
    size="m"
    :heading="$t('modules.personal.modals.noAccessToCamera.heading')"
    :with-mobile-header-style="false"
  >
    <template #description>
      {{ $t('modules.personal.modals.noAccessToCamera.description.part1') }}
      <router-link :to="guideRoute" target="_blank">
        {{ $t('modules.personal.modals.noAccessToCamera.description.part2') }}
      </router-link>
    </template>
    <template #buttons>
      <DialogBtn @click="modal.hide">{{ $t('buttons.gotIt') }}</DialogBtn>
    </template>
  </ModalCard>
</template>

<style scoped lang="scss">
// eslint-disable-next-line vue-scoped-css/no-unused-selector
a {
  text-decoration: underline;
  font-weight: 500;
}
</style>
