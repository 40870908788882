<script lang="ts">
import { defineComponent, h } from 'vue'
import { DialogCard, Btn } from '@keyo/ui'
import { useModal } from '@/composables/useModal'

export default defineComponent({
  extends: DialogCard,
  props: {
    withMobileHeaderStyle: {
      type: Boolean,
      default: true,
    },
    stickToBottom: {
      type: Boolean,
      default: false,
    },
    onBack: {
      type: Function,
    },
  },
  emits: ['back'],
  setup() {
    const modal = useModal()
    return { modal }
  },
  render() {
    const backBtn = h<typeof Btn.props>(Btn, {
      icon: 'left-3',
      kind: 'minimal',
      class: 'btn-icon-back',
      onClick: () => {
        if (this.onBack) {
          this.$emit('back')
        } else {
          this.modal.hide()
        }
      },
    })

    const defaultSlotContent = this.$slots.default ? this.$slots.default() : []
    const mergedDefaultSlot = [backBtn, ...defaultSlotContent]

    return h<typeof DialogCard.props>(
      DialogCard,
      {
        ...this.$props,
        class: [
          this.$attrs.class,
          {
            'with-mobile-header': this.$props.withMobileHeaderStyle,
            'stick-to-bottom': this.$props.stickToBottom,
          },
        ],
      },
      {
        ...this.$slots,
        default: () => (this.$props.withMobileHeaderStyle ? mergedDefaultSlot : defaultSlotContent),
      },
    )
  },
})
</script>

<style lang="scss" scoped>
.dialog-card.modal {
  .btn-icon-back {
    display: none;
  }
}
@media screen and (max-width: $mobile) {
  .dialog-card.modal {
    width: 100%;
    height: 100%;
    max-height: none;
    box-shadow: none;
    border-radius: 0;
    padding: 1rem;

    :deep(h1),
    :deep(p) {
      margin: 0;
    }

    grid-template-rows: min-content min-content 1fr;

    :deep(h1) {
      align-self: end;
    }
    :deep(.line) {
      display: none;
    }
    :deep(.btn-container) {
      margin-top: auto;
      flex-direction: column-reverse;

      .btn {
        flex: auto;
      }
    }

    &.with-mobile-header:not(.stick-to-bottom) {
      :deep(.dialog-icon) {
        display: none;
      }
      :deep(.dialog-content) {
        grid-auto-rows: min-content;
        padding-top: calc(var(--header-height) + 0.5rem);
      }

      :deep(h1) {
        display: flex;
        place-content: center;
        place-items: center;

        position: fixed;
        top: 0;

        font: var(--text-label-l);

        width: 100%;
        height: var(--header-height);

        padding-inline: var(--header-height);
        margin: 0 -1rem;
        border-bottom: 1px solid var(--color-grey-200);
        background: var(--color-white);
        z-index: 1;
      }

      :deep(p) {
        text-align: left;
      }

      :deep(.btn-icon-back) {
        display: flex;
        position: fixed;
        top: 0;
        z-index: 1;
        left: 0;
        background-color: transparent;

        &:before,
        &:hover:not(:disabled):before {
          background-color: unset;
        }
      }
    }

    &.stick-to-bottom {
      width: 100%;
      height: auto;
      box-shadow: none;
      padding: 1.5rem 1.5rem 3rem;
      border-radius: 1rem 1rem 0 0;
      align-self: end;
      :deep(.line) {
        display: none;
      }
      :deep(.btn-container) {
        flex-direction: column-reverse;
      }
      :deep(.btn) {
        flex: auto;
      }
    }
  }
}
</style>
