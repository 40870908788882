<script setup lang="ts">
import { computed, inject, onMounted, reactive, ref, watch } from 'vue'

import { Pin, DialogBtn } from '@keyo/ui'
import type { IconType } from '@keyo/ui'
import { usePersonalStore } from '@/modules/account/pinia'
import type { SignInMethod } from '@/api/auth.ts'
import ModalCard from '@/components/modals/components/ModalCard.vue'
import { BtnResend } from '@/components/BtnResend'
import NonFieldErrors from '@/modules/auth/components/NonFieldErrors.vue'
import { I18nT, useI18n } from 'vue-i18n'
import useFormHelpers from '@/composables/useFormHelpers.ts'
import { mfaCode, required } from '@keyo/core/validations'
import { useVuelidate } from '@vuelidate/core'
import type { AxiosError } from 'axios'
import type { VerifyMethodBody } from '@/modules/account/pinia'
import { useModal } from '@/composables/useModal'
import { type Captcha, captchaInjectionKey } from '@keyo/core'
import { getMethodI18nValue } from '@/modules/account/utils'
import accountApi from '@/modules/account/api'

interface Props {
  method: SignInMethod
  heading?: string
  finished?: () => void
}

const props = defineProps<Props>()

type Errors = { [key: string]: string } & Partial<
  VerifyMethodBody & { non_field_errors: string; client_id: string }
>

const { t } = useI18n()
const modal = useModal()
const personalStore = usePersonalStore()
const { handleResponseException } = useFormHelpers()

const iconByMethod: {
  [key in SignInMethod]: IconType
} = {
  phone: 'call-phone',
  email: 'email',
}

const captcha = inject(captchaInjectionKey) as Captcha

const isSubmitting = ref(false)

const form = reactive<VerifyMethodBody>({
  code: [],
  method: props.method,
  client_id: '',
})

const externalResults = reactive<Errors>({})
const rules = {
  code: [required(), mfaCode()],
}

const v$ = useVuelidate(rules, form, {
  $externalResults: externalResults,
  $rewardEarly: true,
  $autoDirty: true,
})

const methodValue = computed(() => personalStore.profile[props.method] ?? '')
const methodName = computed(() => getMethodI18nValue(props.method))
const methodIcon = computed(() => iconByMethod[props.method])
const headingText = computed(
  () =>
    props.heading ??
    t('modules.auth.common.verifyMethod', {
      method: methodName.value,
    }),
)

async function sendCode() {
  try {
    await captcha.execute()

    const { data } = await accountApi.mfaCodeRequest({
      captcha_token: captcha.token.value,
      [props.method]: methodValue.value,
      method: props.method,
      action: `confirm_${props.method}` as 'confirm_phone' | 'confirm_email',
    })
    form.client_id = data.client_id
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  }
}

async function submit() {
  if (isSubmitting.value) return
  isSubmitting.value = true

  await v$.value.$validate()
  v$.value.$clearExternalResults()
  if (v$.value.$error) {
    isSubmitting.value = false
    return
  }

  try {
    await personalStore.verifyMethod(form)

    if (props.finished) {
      props.finished()
    } else {
      modal.show('AccountVerifyMethodSuccess', { isCustomStyle: true, method: methodName.value })
    }
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  } finally {
    isSubmitting.value = false
  }
}

onMounted(sendCode)

watch(form, () => {
  v$.value.$clearExternalResults()
  v$.value.$reset()
})
</script>

<template>
  <ModalCard
    tag="form"
    :icon="methodIcon"
    icon-color="lavender"
    class="account-verify-method"
    size="m"
    :heading="headingText"
    @submit.prevent="submit"
  >
    <template #description>
      <I18nT keypath="modules.account.common.securityCodeSentToMethod">
        <template #method>
          <strong>{{ methodValue }}</strong>
        </template>
      </I18nT>
    </template>

    <Pin
      class="pin"
      :state="v$.code.$error ? 'error' : ''"
      :tip="v$.code.$errors?.[0]?.$message"
      @input="form.code = $event"
      @complete="submit"
    />

    <NonFieldErrors :errors="externalResults.client_id" />
    <NonFieldErrors :errors="externalResults.captcha_token" />
    <NonFieldErrors :errors="externalResults.non_field_errors" />

    <BtnResend @click="sendCode" />

    <template #buttons>
      <DialogBtn kind="minimal" @click="modal.hide">{{ $t('buttons.cancel') }}</DialogBtn>

      <DialogBtn type="submit" :loading="isSubmitting">
        {{ $t('buttons.verify') }}
      </DialogBtn>
    </template>
  </ModalCard>
</template>

<style lang="scss" scoped>
.account-verify-method.dialog-card.modal {
  :deep(p.description) {
    text-align: center;
  }
}
</style>
