<script lang="ts" setup>
import { DialogBtn, toast } from '@keyo/ui'
import { useModal } from '@/composables/useModal'
import { useMembersStore } from '@/modules/members/pinia'
import { ref, computed } from 'vue'
import { i18nUtils } from '@keyo/core/i18n'
import ModalCard from './components/ModalCard.vue'
import { useRoute, useRouter } from 'vue-router'
import { useMembersLocale } from '@/modules/members/locales'
import type { Profile } from '@/modules/account/pinia'

const props = defineProps<{
  members: Profile[]
}>()
const modal = useModal()
const route = useRoute()
const router = useRouter()
const membersStore = useMembersStore()
const { t } = useMembersLocale()

const list = computed(() => {
  return props.members.map(u => u.email).join(', ')
})

const isSubmitting = ref(false)
async function submit() {
  try {
    if (isSubmitting.value) return
    isSubmitting.value = true
    modal.hide()
    await membersStore.remove(
      route.params.id as string,
      props.members.map(u => u.id),
    )
    toast.show(() => t('membersRemoved', props.members.length), 'success')
    route.params.memberId && router.replace(`/organizations/${route.params.id}/members`)
  } catch (e) {
    console.error(e)
    toast.show(i18nUtils.errorSomethingBroken, 'error')
  } finally {
    isSubmitting.value = false
  }
}
</script>

<template>
  <ModalCard
    tag="form"
    icon="question-circle"
    icon-color="lavender"
    :heading="t('removeMember?', members.length)"
    @submit.prevent="submit"
  >
    <p class="text-body-m">
      {{ t('areYouSureToRemove') }} <strong>{{ list }}</strong
      >?<br />
      {{ t('removingMemberOnlyRevokesAccess') }}
    </p>
    <template #buttons>
      <DialogBtn kind="secondary" @click="modal.hide">
        {{ $t('buttons.cancel') }}
      </DialogBtn>
      <DialogBtn :loading="isSubmitting" type="submit">
        {{ $t('buttons.confirm') }}
      </DialogBtn>
    </template>
  </ModalCard>
</template>
