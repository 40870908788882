export default {
  'Bulk invite': 'Μαζική πρόσκληση',
  'Download': 'Λήψη',
  'Download CSV template': 'Λήψη προτύπου CSV',
  'Download error report': 'Λήψη αναφοράς σφάλματος',
  'Error': 'Σφάλμα',
  'Member email': 'Email μέλους',
  'Member role': 'Ρόλος μέλους',
  'Upload CSV file': 'Μεταφόρτωση αρχείου CSV',
  'acceptsCSVfile': 'Δέχεται αρχείο CSV μικρότερο από 5MB',
  'areYouSureToRemove': 'Είστε βέβαιοι ότι θέλετε να καταργήσετε',
  'doYouWantInviteData?': 'Θέλετε να προσκαλέσετε το { data } να συμμετάσχετε στον οργανισμό σας;',
  'doYouWantInviteMembers?':
    'Θέλετε να προσκαλέσετε { count } μέλη να ενταχθούν στον οργανισμό σας;',
  'ensureFilledCorrectly':
    'Βεβαιωθείτε ότι το αρχείο έχει συμπληρωθεί σωστά και δεν περιέχει σφάλματα.',
  'fillOutTemplate': 'Κάντε λήψη και συμπληρώστε αυτό το πρότυπο για να προσκαλέσετε μέλη.',
  'ignoreRows': 'Παράβλεψη όλων των σειρών με σφάλματα',
  'inviteHasSent': 'Η πρόσκληση έχει σταλεί στο { method }',
  'invitesAreSent': 'Οι προσκλήσεις έχουν σταλεί στους χρήστες.',
  'issuesWithContacts':
    'Έχουμε εντοπίσει επαφές στο αρχείο σας { fileName } οι οποίες δεν μπορούν να λάβουν προσκλήσεις. Κάντε λήψη του αρχείου για να ενημερώσετε τις επαφές που περιέχουν σφάλματα ή προχωρήστε στην αποστολή προσκλήσεων αποκλειστικά σε επαφές χωρίς σφάλματα.',
  'member': 'μέλος | μέλη',
  'members-bulk-invitation-error-report': 'members-bulk-invitation-error-report',
  'membersRemoved': 'Ο @.capitalize:member έχει αφαιρεθεί από τον οργανισμό σας.',
  'noneContactToInvite':
    'Καμία από τις επαφές στο αρχείο { fileName } δεν είναι σε θέση να λάβει την πρόσκληση. Παρακαλούμε μεταφορτώστε ξανά το αρχείο για να ενημερώσετε τις επαφές με σφάλματα.',
  'removeMember?': "Αφαίρεση @:{'member'};",
  'removingMemberOnlyRevokesAccess':
    'Η αφαίρεση ενός @:member από τον οργανισμό ανακαλεί μόνο την πρόσβασή του στον οργανισμό σας και δεν διαγράφει τον λογαριασμό του.',
  'selectRole': 'Επιλέξτε τον ρόλο των μελών του οργανισμού',
  'toInviteMemberCompleteForm': 'Για να προσκαλέσετε ένα μέλος, συμπληρώστε την παρακάτω φόρμα.',
  'verifyRecipientsConsent':
    'Επαληθεύστε ότι όλοι οι παραλήπτες έχουν δώσει τη σαφή συγκατάθεσή τους για να λαμβάνουν emails εγγραφής στην Keyo ώστε να διασφαλιστεί η συμμόρφωση με τους σχετικούς νόμους περί προστασίας δεδομένων και απορρήτου.',
}
