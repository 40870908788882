import { FEATURE_FLAGS } from '@/constants/config.ts'
import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = []

if (FEATURE_FLAGS.ENABLE_ACTIVITY_LOGS.value) {
  routes.push({
    path: 'activity-logs',
    name: 'org.activity-logs',
    component: () => import('@/modules/organization-logs/pages/ActivityLogs.vue'),
    meta: {
      title: 'Activity',
      mainStyles: {
        overflow: 'auto',
      },
      breadcrumbs: [{ label: 'Activity', route: 'org.activity-logs' }],
    },
  })
}

export default routes
