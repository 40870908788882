import auth from '@/pages/auth'
import personal from '@/pages/personal'
import organization from '@/modules/organization/pages'
import zendesk from '@/pages/zendesk'
import { routes as cameraPermissionGuides } from '@/modules/camera-permission-guides'

export const baseRoutes = [
  {
    path: '/%F0%9F%90%A3', // 🐣
    name: 'config',
    component: () => import('@/pages/Config.vue'),
    meta: {
      isOpen: true,
    },
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: () => import('@/pages/NotFoundOrNoAccess.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found-catch',
    component: () => import('@/pages/PageNotFound.vue'),
  },
]

export const routes = [
  ...baseRoutes,
  ...auth,
  ...personal,
  ...organization,
  ...zendesk,
  ...cameraPermissionGuides,
]

const authRoutesNames = auth.filter(r => !r.meta?.ignoreAuthProtectionGuard).map(r => r.name)

export const isAuthRoute = route => authRoutesNames.includes(route.name)
