import { h } from 'vue'
import en from './en'
import es from './es'
import fr from './fr'
import el from './el'

import { I18nT, useI18n } from 'vue-i18n'

export function useOrganizationLocale() {
  const i18n = useI18n({ messages: { en, es, fr, el }, useScope: 'global' })
  function I18nH(key: keyof typeof en, slots?: Parameters<typeof h>[2]) {
    // @ts-ignore broken typecheck for the i18n Composer
    return h(I18nT, { keypath: key, i18n }, slots)
  }
  return {
    ...i18n,
    I18nH,
  }
}
