<script lang="ts">
import type { PropType } from 'vue'
import { h } from 'vue'
import { DialogBtn, type DialogIconColor, type IconPropType } from '@keyo/ui'
import { useModal } from '@/composables/useModal'

import ModalCard from '@/components/modals/components/ModalCard.vue'

export default {
  extends: ModalCard,
  props: {
    icon: {
      type: String as IconPropType,
      default: 'check-circle',
    },
    iconColor: { type: String as PropType<DialogIconColor>, default: 'green' as const },
  },
  setup() {
    return { modal: useModal() }
  },
  render() {
    const props: typeof ModalCard.props = {
      ...this.$props,
      icon: this.icon,
      iconColor: this.iconColor,
      withMobileHeaderStyle: false,
      stickToBottom: true,
    }

    return h<typeof ModalCard.props>(ModalCard, props, {
      buttons: () => h(DialogBtn, { onClick: this.modal.hide }, () => this.$t('buttons.gotIt')),
      ...this.$slots,
    })
  },
}
</script>
