<script setup lang="ts">
import type { PropType } from 'vue'
import { inject, onMounted, reactive, ref } from 'vue'
import { DialogBtn, Pin } from '@keyo/ui'
import { BtnResend } from '@/components/BtnResend'
import NonFieldErrors from '@/modules/auth/components/NonFieldErrors.vue'
import ModalCard from '@/components/modals/components/ModalCard.vue'

import personalApi from '../api/'

import { useModal } from '@/composables/useModal'
import type { ChangePhoneBody } from '../pinia'
import { usePersonalStore } from '../pinia'
import type { AxiosError } from 'axios'
import { external, mfaCode, required } from '@keyo/core/validations'
import { useVuelidate } from '@vuelidate/core'
import useFormHelpers from '@/composables/useFormHelpers.ts'
import { type Captcha, captchaInjectionKey } from '@keyo/core'
import type { Code } from '@/types/code.ts'
import accountApi from '@/modules/account/api'

const props = defineProps({
  form: {
    type: Object as PropType<Partial<ChangePhoneBody>>,
    required: true,
  },
})

const modal = useModal()
const personal = usePersonalStore()
const { handleResponseException } = useFormHelpers()

const form = reactive<ChangePhoneBody>({
  ...props.form,
  code_from_new_phone: [],
  captcha_token: '',
})

type Errors = { [key: string]: string } & Partial<ChangePhoneBody & { non_field_errors: string }>
const externalResults = reactive<Errors>({})
const rules = {
  code_from_new_phone: [required(), mfaCode()],
  captcha_token: [external],
}

const v$ = useVuelidate(
  rules,
  form as {
    code_from_new_phone: Code
    captcha_token: string
  },
  {
    $externalResults: externalResults,
    $autoDirty: true,
    $rewardEarly: true,
  },
)

const captcha = inject(captchaInjectionKey) as Captcha

const isSubmitting = ref(false)

const requestCode = async () => {
  try {
    await captcha.execute()

    form.captcha_token = captcha.token.value

    const { data } = await accountApi.mfaCodeRequest({
      method: 'phone',
      phone: form.new_phone as string,
      action: 'confirm_phone',
      captcha_token: form.captcha_token,
    })
    form.client_id_from_new_phone = data?.client_id
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  }
}

async function submit() {
  if (isSubmitting.value) return
  isSubmitting.value = true
  await v$.value.$validate()
  if (v$.value.$error) {
    isSubmitting.value = false
    return
  }
  try {
    // change phone is not an atomic operation, so we need to validate it first
    await personalApi.validateChangePhone(form)
    await personal.changePhone(form)
    modal.show('account-phone-change-success', { isCustomStyle: true })
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  } finally {
    isSubmitting.value = false
  }
}

onMounted(requestCode)
</script>

<template>
  <ModalCard
    tag="form"
    icon="call-phone"
    icon-color="lavender"
    size="m"
    :heading="$t('modules.account.modals.accountPhoneChangeStep3.heading')"
    :description="
      $t('modules.account.modals.accountPhoneChangeStep3.description', {
        newPhone: props.form.new_phone,
      })
    "
    @submit.prevent="submit"
  >
    <Pin
      class="pin"
      :state="v$.code_from_new_phone.$error ? 'error' : ''"
      :tip="v$.code_from_new_phone.$errors?.[0]?.$message"
      @input="form.code_from_new_phone = $event"
    />

    <NonFieldErrors :errors="externalResults.captcha_token" />
    <NonFieldErrors :errors="externalResults.non_field_errors" />

    <BtnResend @click="requestCode" />
    <template #buttons>
      <DialogBtn kind="secondary" @click="modal.hide">
        {{ $t('buttons.cancel') }}
      </DialogBtn>
      <DialogBtn :loading="isSubmitting" type="submit">
        {{ $t('buttons.continue') }}
      </DialogBtn>
    </template>
  </ModalCard>
</template>

<style scoped>
.pin {
  justify-self: center;
}
</style>
