export default [
  {
    path: '/organizations/:id/devices',
    meta: {
      requiresOrgAccess: true,
    },
    children: [
      {
        path: '',
        name: 'org.devices',
        component: () => import('@/modules/organization/pages/devices/DevicesList.vue'),
        meta: {
          title: 'Device management',
          breadcrumbs: [{ label: 'Device management', route: 'org.devices' }],
        },
      },
      {
        path: '/organizations/:id/devices/:deviceId',
        component: () => import('@/modules/organization/pages/devices/DeviceProfile.vue'),
        meta: {
          title: 'Device management',
          breadcrumbs: [{ label: 'Device management', route: 'org.devices' }],
        },
        children: [
          {
            path: '',
            name: 'org.device',
            component: () => import('@/modules/organization/pages/devices/DeviceAbout.vue'),
          },
          {
            path: 'mangers',
            name: 'org.device.managers',
            component: () => import('@/modules/organization/pages/devices/DeviceManagers.vue'),
          },
          {
            path: 'location',
            name: 'org.device.location',
            component: () => import('@/modules/organization/pages/devices/DeviceLocation.vue'),
          },
        ],
      },
    ],
  },
]
