<script setup lang="ts">
import { inject, onMounted, reactive, ref } from 'vue'

import { DialogBtn, Pin } from '@keyo/ui'
import { BtnResend } from '@/components/BtnResend'
import NonFieldErrors from '@/modules/auth/components/NonFieldErrors.vue'
import ModalCard from '@/components/modals/components/ModalCard.vue'

import personalApi from '../api'

import { usePersonalStore } from '@/modules/account/pinia'
import { useModal } from '@/composables/useModal'

import useFormHelpers from '@/composables/useFormHelpers'
import type { DeleteByMethodBody } from '@/modules/account/pinia/index.js'
import { mfaCode, required } from '@keyo/core/validations'
import { useVuelidate } from '@vuelidate/core'
import type { AxiosError } from 'axios'
import CardChangeMfaMethod from '@/modules/account/components/CardChangeMfaMethod.vue'
import type { SignInMethod } from '@/api/auth.ts'
import BtnChangeMfa from '@/components/BtnChangeMfa/BtnChangeMfa.vue'
import { getMethodI18nValue } from '../utils'
import { type Captcha, captchaInjectionKey } from '@keyo/core'

const props = defineProps<{
  method?: SignInMethod
}>()

const captcha = inject(captchaInjectionKey) as Captcha

const isSubmitting = ref(false)

const modal = useModal()
const personal = usePersonalStore()
const { handleResponseException } = useFormHelpers()
const changingMethod = ref(false)

const method = ref(props.method ?? personal.preferableMfaMethod)

const form = reactive<DeleteByMethodBody>({
  code: [],
  client_id: '',
})

type Errors = { [key: string]: string } & Partial<DeleteByMethodBody & { non_field_errors: string }>
const externalResults = reactive<Errors>({})
const rules = {
  code: [required(), mfaCode()],
}

const v$ = useVuelidate(rules, form, {
  $externalResults: externalResults,
  $autoDirty: true,
  $rewardEarly: true,
})

async function sendCode(currentMethod?: SignInMethod) {
  try {
    await captcha.execute()
    changingMethod.value = false
    method.value = currentMethod ?? method.value

    const { data } = await personalApi.mfaCodeRequest({
      action: 'delete_biometric_data',
      method: method.value,
      captcha_token: captcha.token.value,
    })

    form.client_id = data?.client_id
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  }
}

async function submit() {
  if (isSubmitting.value) return
  isSubmitting.value = true
  await v$.value.$validate()
  if (v$.value.$error) {
    isSubmitting.value = false
    return
  }

  try {
    await personal.palmsDelete({
      code: form.code,
      client_id: form.client_id,
    })
    modal.show('account-biometric-delete-success', { isCustomStyle: true })
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  } finally {
    isSubmitting.value = false
  }
}

onMounted(sendCode)
</script>

<template>
  <ModalCard
    v-if="!changingMethod"
    tag="form"
    icon="delete"
    icon-color="red"
    :heading="$t('modules.account.modals.accountBiometricDelete.heading')"
    @submit.prevent="submit"
  >
    <p class="text-body-m">
      {{
        $t('modules.account.modals.accountBiometricDelete.description.part1', {
          method: getMethodI18nValue(method),
        })
      }}
      <strong>{{ $t('modules.account.modals.accountBiometricDelete.deleteBiometric') }}</strong
      >{{ $t('modules.account.modals.accountBiometricDelete.description.part3') }}
    </p>
    <Pin
      class="pin"
      :state="v$.code.$error ? 'error' : ''"
      :tip="v$.code.$errors?.[0]?.$message"
      @input="form.code = $event"
    />
    <NonFieldErrors :errors="externalResults.non_field_errors" />
    <BtnResend @click="() => sendCode()" />
    <BtnChangeMfa @click="changingMethod = true" />

    <template #buttons>
      <DialogBtn kind="secondary" @click="modal.hide">
        {{ $t('buttons.cancel') }}
      </DialogBtn>
      <DialogBtn danger :loading="isSubmitting" type="submit">
        {{ $t('modules.account.modals.accountBiometricDelete.deleteBiometric') }}
      </DialogBtn>
    </template>
  </ModalCard>

  <CardChangeMfaMethod
    v-else
    :method="method"
    @canceled="changingMethod = false"
    @changed="sendCode"
  />
</template>

<style scoped>
.pin {
  justify-self: center;
}
</style>
