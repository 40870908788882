<script setup lang="ts">
import { inject, onMounted, reactive, ref } from 'vue'
import { DialogBtn, Pin } from '@keyo/ui'
import { BtnResend } from '@/components/BtnResend'
import NonFieldErrors from '@/modules/auth/components/NonFieldErrors.vue'
import ModalCard from '@/components/modals/components/ModalCard.vue'

import personalApi from '../api/'

import { useModal } from '@/composables/useModal'
import useInputSize from '@/composables/useInputSize'
import type { ChangePhoneBody } from '../pinia'
import { usePersonalStore } from '../pinia'
import type { AxiosError } from 'axios'
import { mfaCode, required, external } from '@keyo/core/validations'
import { useVuelidate } from '@vuelidate/core'
import useFormHelpers from '@/composables/useFormHelpers.ts'
import type { Code } from '@/types/code.ts'
import { type Captcha, captchaInjectionKey } from '@keyo/core'

const captcha = inject(captchaInjectionKey) as Captcha
const modal = useModal()
const size = useInputSize()
const personal = usePersonalStore()
const { handleResponseException } = useFormHelpers()

const form = reactive<ChangePhoneBody>({
  code_from_old_phone: [],
  client_id_from_old_phone: '',
})

type Errors = { [key: string]: string } & Partial<ChangePhoneBody & { non_field_errors: string }>

const externalResults = reactive<Errors>({})
const rules = {
  code_from_old_phone: [required(), mfaCode()],
  client_id_from_old_phone: [external],
}

const v$ = useVuelidate(
  rules,
  form as { code_from_old_phone: Code; client_id_from_old_phone: string },
  {
    $externalResults: externalResults,
    $autoDirty: true,
    $rewardEarly: true,
  },
)

const isSubmitting = ref(false)

const requestCode = async () => {
  try {
    await captcha.execute()
    const response = await personalApi.mfaCodeRequest({
      method: 'phone',
      action: 'update_phone',
      captcha_token: captcha.token.value,
    })
    form.client_id_from_old_phone = response?.data?.client_id
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  }
}

async function submit() {
  if (isSubmitting.value) return
  isSubmitting.value = true
  await v$.value.$validate()
  if (v$.value.$error) {
    isSubmitting.value = false
    return
  }

  try {
    await personalApi.validateChangePhone(form)
    modal.show('account-phone-change-step2', { isCustomStyle: true, form })
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  } finally {
    isSubmitting.value = false
  }
}

onMounted(requestCode)
</script>

<template>
  <ModalCard
    tag="form"
    icon="call-phone"
    icon-color="lavender"
    size="m"
    :heading="$t('modules.account.common.changePhoneNumber')"
    :description="$t('modules.account.common.securityCodeSentToPhone', { phone: personal.phone })"
    @submit.prevent="submit"
  >
    <Pin
      class="pin"
      :state="v$.code_from_old_phone.$error ? 'error' : ''"
      :tip="v$.code_from_old_phone.$errors?.[0]?.$message"
      @input="form.code_from_old_phone = $event"
    />
    <NonFieldErrors :errors="externalResults.non_field_errors" />
    <BtnResend @click="requestCode" />
    <template #buttons>
      <DialogBtn :size="size" kind="secondary" @click="modal.hide">
        {{ $t('buttons.cancel') }}
      </DialogBtn>
      <DialogBtn :size="size" :loading="isSubmitting" type="submit">
        {{ $t('buttons.continue') }}
      </DialogBtn>
    </template>
  </ModalCard>
</template>
<style scoped>
.pin {
  justify-self: center;
}
</style>
