<script setup lang="ts">
import type { PropType } from 'vue'
import { reactive, ref } from 'vue'
import type { TextFieldSize } from '@keyo/ui'
import { DialogBtn, PhoneField } from '@keyo/ui'
import NonFieldErrors from '@/modules/auth/components/NonFieldErrors.vue'
import ModalCard from '@/components/modals/components/ModalCard.vue'

import personalApi from '../api/'

import { useModal } from '@/composables/useModal'
import useInputSize from '@/composables/useInputSize'
import type { AxiosError } from 'axios'
import { phone, required } from '@keyo/core/validations'
import { useVuelidate } from '@vuelidate/core'
import useFormHelpers from '@/composables/useFormHelpers.ts'
import type { ChangePhoneBody } from '@/modules/account/pinia'

const props = defineProps({
  form: {
    type: Object as PropType<ChangePhoneBody>,
    required: true,
  },
})

type Errors = { [key: string]: string } & Partial<ChangePhoneBody & { non_field_errors: string }>

const size = useInputSize<TextFieldSize>()
const { handleResponseException } = useFormHelpers()

const form = reactive<ChangePhoneBody>({
  ...props.form,
  new_phone: '',
})

const externalResults = reactive<Errors>({})
const rules = {
  new_phone: [required(), phone()],
}

const v$ = useVuelidate(rules, form as { new_phone: string }, {
  $externalResults: externalResults,
  $autoDirty: true,
  $rewardEarly: true,
})

const isSubmitting = ref(false)

const modal = useModal()

async function submit() {
  if (isSubmitting.value) return
  isSubmitting.value = true
  await v$.value.$validate()
  if (v$.value.$error) {
    isSubmitting.value = false
    return
  }
  try {
    await personalApi.validateChangePhone(form)
    modal.show('account-phone-change-step3', { isCustomStyle: true, form })
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  } finally {
    isSubmitting.value = false
  }
}
</script>

<template>
  <ModalCard
    tag="form"
    icon="call-phone"
    icon-color="lavender"
    size="m"
    :heading="$t('modules.account.common.changePhoneNumber')"
    :description="$t('modules.account.modals.accountPhoneChangeStep2.description')"
    @submit.prevent="submit"
  >
    <PhoneField
      id="phone"
      v-model="form.new_phone"
      :size="size"
      :disabled="isSubmitting"
      :placeholder="$t('modules.account.modals.accountPhoneChangeStep2.labels.enterNewPhone')"
      :state="v$.new_phone.$error ? 'error' : ''"
      :tip="v$.new_phone.$errors?.[0]?.$message"
    />
    <NonFieldErrors :errors="externalResults.non_field_errors" />
    <template #buttons>
      <DialogBtn kind="secondary" @click="modal.hide">
        {{ $t('buttons.cancel') }}
      </DialogBtn>
      <DialogBtn :loading="isSubmitting" type="submit">
        {{ $t('buttons.continue') }}
      </DialogBtn>
    </template>
  </ModalCard>
</template>
