export default {
  'Enable webhook?': 'Enable webhook?',
  'Enable': 'Enable',
  'enablingWebhookStartEvents':
    'By enabling this webhook endpoint it will start receiving notifications about events.',

  'Disable webhook?': 'Disable webhook?',
  'Disable': 'Disable',
  'disablingWebhookStopEvents':
    'By temporary disabling this webhook endpoint it will stop receiving notifications about events.',

  'Remove webhook?': 'Remove webhook?',
  'removingWebhookNoEventSent':
    'Removing this webhook means that no webhook messages will be sent whenever this event occurs within your organization.',
  'Add endpoint': 'Add endpoint',
  'Endpoint URL': 'Endpoint URL',
  'Secret': 'Secret',
  'Select events': 'Select events',
  'Add': 'Add',
  'Edit endpoint': 'Edit endpoint',
  'Save': 'Save',

  'The webhook endpoint was saved successfully': 'The webhook endpoint was saved successfully.',
  'The webhook endpoint was added successfully': 'The webhook endpoint was added successfully.',
  'Failed to retrieve webhook secret': 'Failed to retrieve webhook secret.',

  'URL': 'URL',
  'Events': 'Events',
  'Status': 'Status',
  'Created': 'Created',
  'Set': 'Set',
  'Missing': 'Missing',
  'Active': 'Active',
  'Disabled': 'Disabled',
  'Edit webhook': 'Edit webhook',
  'Disable webhook': 'Disable webhook',
  'Enable webhook': 'Enable webhook',
  'Remove webhook': 'Remove webhook',
  'No webhooks added': 'No webhooks added',
  'toGetStartedClick': 'To get started click “Add endpoint” to add one.',
}
