<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import { toast, DialogBtn } from '@keyo/ui'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { whenever } from '@vueuse/core'
import type { AxiosError } from 'axios'

import ModalCard from '@/components/modals/components/ModalCard.vue'

import { useModal } from '@/composables/useModal'

import { useDevicesStore, useOrganizationsStore } from '@/store'
import { usePersonalStore } from '../pinia'
import { getDeviceProfileExposedData } from '@/api/organization'

import useFormHelpers from '@/composables/useFormHelpers'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@keyo/core/validations'
import NonFieldErrors from '@/modules/auth/components/NonFieldErrors.vue'

import { useI18n } from 'vue-i18n'
import LegalBanner from '@/modules/organization/components/LegalBanner.vue'
import OrganizationDataHeader from '@/modules/organization/components/OrganizationDataHeader.vue'
import DeviceOrganizationAddress from '@/modules/account/components/DeviceOrganizationAddress.vue'
import formatAddress from '@/utils/formatAddress.ts'

type Form = {
  organization_id: string
  device_id: string
}

const props = defineProps({
  organizationId: {
    type: String,
    required: true,
  },
  deviceId: {
    type: String,
    required: true,
  },
})

const { t } = useI18n()
const personalStore = usePersonalStore()
const devicesStore = useDevicesStore()
const { startEnroll } = devicesStore
const { isProfileSet } = storeToRefs(personalStore)
const modal = useModal()
const router = useRouter()
const organizations = useOrganizationsStore()
const { handleResponseException } = useFormHelpers()

const deviceOrgData = ref()
const isLoaded = ref(false)
const isSubmitting = ref(false)
const followWaveInstructions = ref(false)

const formattedAddress = computed(() => formatAddress(deviceOrgData.value?.location))

const organization = computed(() => {
  const storeOrg = organizations.items.get(String(deviceOrgData.value?.organization.id))
  return deviceOrgData.value?.organization
    ? {
        ...deviceOrgData.value?.organization,
        displayName: storeOrg?.displayName || '',
        description: storeOrg?.description || '',
      }
    : null
})

const form = reactive({
  organization_id: '',
  device_id: '',
})

type Errors = { [key: string]: string } & Partial<Form & { non_field_errors: string }>

const externalResults = reactive<Errors>({})

const rules = {
  organization_id: [required()],
  device_id: [required()],
}

const v$ = useVuelidate(rules, form, { $externalResults: externalResults })

const closeModal = () => {
  router.replace({ name: 'personal' })
  modal.hide()
}

const showQRCodeError = () => {
  closeModal()
  toast.show(() => t('modules.personal.modals.qrScanner.invalidQrMessage'), 'error')
}

const loadModal = async () => {
  form.organization_id = props.organizationId as string
  form.device_id = props.deviceId as string

  if (!form.organization_id || !form.device_id) {
    showQRCodeError()
    return
  }

  try {
    const { data } = await getDeviceProfileExposedData(form.organization_id, form.device_id)
    deviceOrgData.value = data
    isLoaded.value = true
  } catch (error) {
    showQRCodeError()
  }
}

const submit = async () => {
  try {
    if (isSubmitting.value) return
    isSubmitting.value = true
    await v$.value.$validate()
    if (v$.value.$error) {
      isSubmitting.value = false
      return
    }

    await startEnroll(Number(form.organization_id), Number(form.device_id))
    followWaveInstructions.value = true
  } catch (error) {
    const { response } = error as AxiosError
    handleResponseException(response, externalResults)
  } finally {
    isSubmitting.value = false
  }
}

whenever(
  () => isProfileSet.value,
  () => {
    loadModal()
  },
  { immediate: true },
)
</script>

<template>
  <ModalCard
    v-if="!followWaveInstructions"
    tag="form"
    class="account-biometric-confirm-enroll"
    :with-mobile-header-style="false"
    @submit.prevent="submit"
  >
    <OrganizationDataHeader :organization="organization" :is-loading="!isLoaded" />

    <main>
      <div class="org-info">
        <p class="text-heading-s">
          {{ t('modules.account.modals.accountBiometricConfirmEnroll.startEnrollment') }}
        </p>
        <p v-if="formattedAddress" class="text-body-m org-info-desc">
          {{ t('modules.account.modals.accountBiometricConfirmEnroll.startEnrollmentQuestion') }}
        </p>
      </div>

      <DeviceOrganizationAddress
        :class="{
          skeleton: !deviceOrgData,
          hide: deviceOrgData && !formattedAddress,
        }"
        :address="formattedAddress"
      />

      <NonFieldErrors
        v-if="v$.device_id.$error"
        :errors="t('modules.account.modals.accountBiometricConfirmEnroll.deviceMissingError')"
        class="error"
      />
      <NonFieldErrors
        v-if="v$.organization_id.$error"
        :errors="t('modules.account.modals.accountBiometricConfirmEnroll.organizationMissingError')"
        class="error"
      />
      <NonFieldErrors
        v-if="externalResults.non_field_errors"
        :errors="externalResults.non_field_errors"
        class="error"
      />
    </main>

    <template #buttons>
      <LegalBanner
        :terms-of-use="organization?.terms_of_use"
        :privacy-policy="organization?.privacy_policy"
      />

      <div class="buttons">
        <DialogBtn kind="secondary" :disabled="isSubmitting" @click="closeModal">
          {{ t('buttons.cancel') }}
        </DialogBtn>
        <DialogBtn :disabled="v$.$invalid" :loading="isSubmitting" type="submit">
          {{ t('buttons.continue') }}
        </DialogBtn>
      </div>
    </template>
  </ModalCard>

  <ModalCard
    v-else
    class="account-biometric-confirm-enroll"
    :heading="t('modules.account.modals.accountBiometricConfirmEnroll.almostDone')"
    :description="t('modules.account.modals.accountBiometricConfirmEnroll.followInstructions')"
    :with-mobile-header-style="false"
  >
    <template #header>
      <video playsinline autoplay loop muted poster="../assets/videos/white-thumbnail.png">
        <source src="../assets/videos/bio-review-v0.2.webm" type="video/webm" />
        <source src="../assets/videos/bio-review-v0.2.mp4" type="video/mp4" />
      </video>
    </template>
    <template #buttons>
      <DialogBtn @click="closeModal">{{ t('buttons.gotIt') }}</DialogBtn>
    </template>
  </ModalCard>
</template>

<style scoped lang="scss">
.account-biometric-confirm-enroll {
  &.dialog-card.modal {
    display: flex;
    padding: 0;
  }

  :deep(.dialog-content) {
    margin-bottom: 0;
    grid-template-rows: max-content 1fr;
    flex: 1;
    gap: 0;
  }

  .hide {
    display: none;
  }

  video,
  :deep(.dialog-icon) {
    aspect-ratio: 16 / 10;
    margin-bottom: 1.5rem;
    border-radius: 1.25rem;
    width: 100%;
    height: unset;
    object-fit: cover;
  }

  :deep(.org-avatar) {
    width: 10rem;
    height: 10rem;
    border: 1px solid var(--color-grey-400);
    border-radius: 2rem;

    span {
      font: var(--text-heading-xxl);
    }
  }

  main {
    background: #fff;
    display: flex;
    padding: 2rem 2rem 1.5rem;
    width: 100%;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 1rem;

    // TODO: Look into DialogCard's .dialog-content to refactor styles or html/slot structure to avoid grid and gap overcomplicating things and make it more flexible
    .org-info {
      display: grid;
      gap: 0.75rem;
      text-align: center;
    }

    .org-info-desc {
      color: var(--color-grey-700);
    }
  }

  :deep(.line) {
    margin: 0 2rem 1.5rem;
  }

  :deep(.btn-container) {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem 1.5rem;

    .btn {
      flex: auto;
    }
  }

  .buttons {
    display: flex;
    gap: 1rem;
  }
}

@media screen and (max-width: $mobile) {
  .account-biometric-confirm-enroll.dialog-card.modal {
    :deep(.btn-container) {
      flex-direction: column;
    }
    .buttons {
      margin-top: auto;
      flex-direction: column-reverse;
    }

    main {
      align-items: flex-start;

      .org-info {
        text-align: left;
      }
    }
  }

  :deep(.line) {
    display: none;
  }
}
</style>
