import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import { useOrganizationsStore } from '@/store'
import { usePersonalStore } from '@/modules/account/pinia'
import { clearTokens, getRefreshJwt } from '@/api'
import { jwt } from '@/api/utils'
import { toast } from '@keyo/ui'

import { MOBILE_HASH_TOGGLE } from '@/constants/sidebar'
import { isAuthRoute, routes } from '@/pages/routes'

import { i18n } from '@keyo/core/i18n'
import type { Organization, RoleType } from '@/modules/organization/types/model.ts'
import { MEMBERSHIP_STATUS } from '@/modules/organization/types/model.ts'
import { useAuthN } from '@/composables/useAuthN.ts'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    savedPosition: { top: number } | null,
  ) {
    // prevent scroll to top when sidebar toggles
    if ([to.hash, from.hash].includes(MOBILE_HASH_TOGGLE)) {
      return {}
    }

    if (to.hash) return { el: to.hash }

    // prevent scroll to top when modal toggles
    if (to.query.modal || from.query.modal) {
      return {}
    }
    if (to.path === from.path) {
      return { top: 0, behavior: 'smooth' }
    }
    return savedPosition ?? { top: 0 }
  },
})

async function getOrg(orgId: string) {
  const orgs = useOrganizationsStore()
  const org = orgs.items.get(orgId)
  if (org) {
    return org
  } else {
    await orgs.getList()
    return orgs.items.get(orgId)
  }
}

async function getOrgPermissions(orgId: string, allowedRoles: RoleType[] = []) {
  try {
    const org = (await getOrg(orgId)) as Organization
    const isRoleAllowed = allowedRoles.length ? allowedRoles.includes(org.member.role) : true

    return {
      hasOrgAccess:
        org.member.role !== 'User' && org.member.status.value === MEMBERSHIP_STATUS.ACTIVE,
      isOrgOwner: org.member.id === org.owner?.member_id,
      isRoleAllowed,
    }
  } catch (e) {
    return {
      hasOrgAccess: false,
      isOrgOwner: false,
      isRoleAllowed: false,
    }
  }
}

async function canAccessRoute(to: RouteLocationNormalized) {
  if (!to.meta.requiresOrgAccess && !to.meta.requiresOrgOwner) {
    return true
  }

  const permissions = await getOrgPermissions(
    to.params.id as string,
    to.meta.orgAllowedRoles as RoleType[],
  )

  if (!permissions.isRoleAllowed) {
    return false
  }

  if (to.meta.requiresOrgAccess && !permissions.hasOrgAccess) {
    return false
  }

  if (to.meta.requiresOrgOwner && !permissions.isOrgOwner) {
    return false
  }

  return true
}

let throttleTimer: ReturnType<typeof setTimeout> | null = null

export async function afterRouteGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  const personal = usePersonalStore()
  const orgStore = useOrganizationsStore()
  const authN = useAuthN()

  if (isAuthRoute(to)) {
    // NOTE[id=logout-router-guard] handles logout - always clear token
    clearTokens()
    next()
    return
  }
  if (to.meta.isOpen) {
    next()
    return
  }

  const refreshToken = getRefreshJwt()
  if (jwt.isExpired(refreshToken)) {
    toast.show(refreshToken ? i18n.global.t('Session expired') : i18n.global.t('Please log in'))
    authN.clearSession()
    next({ name: 'login', query: { redirect: to.fullPath } })
    return
  }

  const handleProfileFetchError = () => {
    // force logout if profile fetch fails
    authN.clearSession()
    // Not using next since it is an async action from failed profile fetch
    router.replace({ name: 'login', query: { redirect: to.fullPath } })
  }

  // TODO: temporary solution to always fetch up to date mandatory data for signed-in users
  if (to.name !== from.name && throttleTimer === null) {
    // If profile is not set then fetch it synchronously to block navigation until it's set
    // If profile is set then just fetch it asynchronously to keep it up to date
    personal.isProfileSet
      ? personal.profileFetch().catch(handleProfileFetchError)
      : await personal.profileFetch().catch(handleProfileFetchError)

    orgStore.getList()
    throttleTimer = setTimeout(() => {
      throttleTimer = null
    }, 10000)
  }
  // to fetch full org profile
  if (to.params.id && to.params.id !== from.params.id) {
    orgStore.fetchById(to.params.id as string)
  }

  // Check if the current route is the home page
  if (to.fullPath === '/') {
    next({ name: 'personal' })
    return
  }

  const isNavigationAllowed = await canAccessRoute(to)
  if (!isNavigationAllowed) {
    next({ name: 'not-found' })
    return
  }

  next()
  return
}

export default router
