<script setup lang="ts">
import { DialogBtn } from '@keyo/ui'
import ModalCard from '@/components/modals/components/ModalCard.vue'
import { useModal } from '@/composables/useModal'
import { isAxiosError } from '@/composables/useAuthN'
import { useMembersStore } from '@/modules/members/pinia'
import { useDevicesStore } from '@/store'
import useFormHelpers from '@/composables/useFormHelpers'
import { ref } from 'vue'
import { useCurrentRouteEntities } from '@/composables/useCurrentRouteEntities'
import type { DeviceManager } from '@/modules/organization/types/model'

const props = defineProps<{
  manager: DeviceManager
  deviceName: string
}>()

const isRemovingManager = ref(false)

const modal = useModal()
const members = useMembersStore()
const devices = useDevicesStore()
const { handleResponseException } = useFormHelpers()
const { device, organization } = useCurrentRouteEntities()

async function handleConfirm() {
  try {
    isRemovingManager.value = true

    if (!organization.value?.id || !device.value?.id) return

    await devices.removeDeviceManager(organization.value?.id, device.value?.id, props.manager.id)

    // async update the organization managers  after removing the manager from device
    members.fetchManagers(organization.value?.id)

    modal.hide()
  } catch (error) {
    if (!isAxiosError(error)) throw error

    handleResponseException(
      error.response,
      {},
      {
        useToast: true,
      },
    )
  } finally {
    isRemovingManager.value = false
  }
}
</script>

<template>
  <ModalCard
    tag="form"
    :with-mobile-header-style="false"
    stick-to-bottom
    :heading="$t('Confirm removal')"
    icon="question-circle"
    icon-color="yellow"
    @submit.prevent="handleConfirm"
  >
    <template #description>
      <i18n-t
        tag="p"
        class="remove-description text-body-s"
        keypath="wouldYouLikeToRemoveMemberAsAssignedManagerOfDevice"
      >
        <template #memberName>
          <strong>{{ manager.full_name }}</strong>
        </template>

        <template #deviceName>
          <strong>{{ deviceName }}</strong>
        </template>
      </i18n-t>
    </template>
    <template #buttons>
      <DialogBtn kind="secondary" @click="modal.hide">{{ $t('buttons.cancel') }}</DialogBtn>
      <DialogBtn type="submit" :loading="isRemovingManager">
        {{ $t('Remove') }}
      </DialogBtn>
    </template>
  </ModalCard>
</template>

<style lang="scss" scoped>
.remove-description {
  word-break: break-word;
}
</style>
