export default {
  'Add manager': 'Προσθήκη διαχειριστή',
  'Assign a manager to this device': 'Ορίστε έναν διαχειριστή σε αυτήν τη συσκευή.',
  'Assign manager': 'Ορισμός διαχειριστή',
  'Assigned managers': 'Διορισμένοι διαχειριστές',
  'Assignee': 'Εκπρόσωπος',
  'Confirm removal': 'Επιβεβαίωση αφαίρεσης',
  'Location view': 'Προβολή τοποθεσίας',
  'Managers currently assigned to this device':
    'Διαχειριστές που έχουν οριστεί σε αυτή τη συσκευή.',
  'Managers will appear here once they\u2019ve been assigned':
    'Οι διαχειριστές θα εμφανιστούν εδώ μόλις οριστούν.',
  'Model': 'Μοντέλο',
  'No managers assigned': 'Δεν έχουν οριστεί διαχειριστές',
  'No managers available to assign': 'Δεν υπάρχουν διαθέσιμοι διαχειριστές για ορισμό',
  'Photos of the terminal\u2019s area to show its location':
    'Φωτογραφίες της περιοχής του τερματικού σταθμού ώστε να φαίνεται η θέση του.',
  'Provide a name to easily identify the device':
    'Παραθέστε ένα όνομα για να αναγνωρίσετε εύκολα τη συσκευή.',
  'Provide the official address of the device': 'Παραθέστε την επίσημη διεύθυνση της συσκευής.',
  'SN': 'SN',
  'Select manager': 'Επιλογή διαχειριστή',
  'There are currently no managers you can assign. Check back later or update the manager list':
    'Προς το παρόν δεν υπάρχουν διαχειριστές που μπορείτε να ορίσετε. Ελέγξτε ξανά αργότερα ή ενημερώστε τη λίστα των διαχειριστών.',
  'Unexpected error during updating device profile':
    'Μη αναμενόμενο σφάλμα κατά την ενημέρωση του προφίλ της συσκευής',
  'usersHaveBeenSuccessfullyAssigned':
    '{count} έχει οριστεί επιτυχώς στη συσκευή. | {count} έχουν οριστεί επιτυχώς στη συσκευή.',
  'wouldYouLikeToRemoveMemberAsAssignedManagerOfDevice':
    'Θέλετε να αφαιρέσετε τον {memberName} ως διορισμένο διαχειριστή του {deviceName};',
}
