<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { required } from '@keyo/core/validations'
import { toast, DialogBtn, Dropdown } from '@keyo/ui'

import type { UpdateMembershipRoleBody } from '@/modules/members/pinia'
import { useMembersStore } from '@/modules/members/pinia'

import type { RoleType } from '@/modules/organization/types/model.ts'
import { useCurrentRouteEntities } from '@/composables/useCurrentRouteEntities.ts'
import { useModal } from '@/composables/useModal.ts'
import useForm from '@/composables/useForm.ts'
import ModalCard from '@/components/modals/components/ModalCard.vue'
import NonFieldErrors from '@/modules/auth/components/NonFieldErrors.vue'
import type { DropdownOption } from '@keyo/ui'
import { useOrganizationLocale } from '@/modules/organization/locales'

type RoleOption = DropdownOption & { originalTitle: string }

const props = defineProps<{
  membershipId: number
  userRole?: RoleType
}>()

const route = useRoute()
const { t } = useOrganizationLocale()
const modal = useModal()
const members = useMembersStore()

const { organization } = useCurrentRouteEntities()

const rolesOptions = computed<RoleOption[]>(() => {
  const options: RoleOption[] = []
  organization?.value?.roles.forEach(r => {
    if (r.name === 'Owner') return
    options.push({
      originalTitle: r.name,
      id: r.id,
      label: t(r.name),
      value: String(r.id),
    })
  })
  return options
})

// Pre-select the user’s current role
const currentRole = rolesOptions.value.find(r => r.originalTitle === props.userRole)

const initialForm: UpdateMembershipRoleBody = {
  organization_role: currentRole?.value ?? '',
}

const rules = {
  organization_role: [required()],
}

const { form, isSubmitting, externalResults, errors, submitForm } = useForm(initialForm, rules)

async function submit() {
  submitForm(async () => {
    await members.updateMembershipRole(route.params.id as string, props.membershipId, form)

    // Refresh the managers list
    members.fetchManagers(route.params.id as string)
    toast.show(() => t('Role has been changed successfully'), 'success')
    modal.hide()
  })
}
</script>

<template>
  <ModalCard
    class="change-role-modal"
    tag="form"
    icon="user-filled"
    icon-color="lavender"
    :heading="$t('Change member role')"
    :description="$t('To change member role, please choose role in the field provided below')"
    :with-mobile-header-style="false"
    stick-to-bottom
    @submit.prevent="submit"
  >
    <Dropdown
      v-model="form.organization_role"
      :options="rolesOptions"
      :label="$t('Organization role')"
      :state="errors.organization_role ? 'error' : ''"
      :tip="errors.organization_role"
      :clearable="false"
    />

    <NonFieldErrors :errors="externalResults.non_field_errors" />

    <template #buttons>
      <DialogBtn kind="secondary" @click="modal.hide()">
        {{ $t('buttons.cancel') }}
      </DialogBtn>
      <DialogBtn :loading="isSubmitting" type="submit">
        {{ $t('buttons.save') }}
      </DialogBtn>
    </template>
  </ModalCard>
</template>

<style lang="scss" scoped>
// eslint-disable-next-line vue-scoped-css/no-unused-selector
.dropdown {
  // Custom height to avoid the dropdown from being cut off by the bottom of the screen and overflow parent container
  --dropdown-menu-height: 8rem;
}
</style>
