export default {
  'Beta': 'Βήτα',
  'attributes': 'χαρακτηριστικά',
  'Device': 'Συσκευή',
  'Enrollment app': 'Εφαρμογή εγγραφής',
  'Account overview': 'Επισκόπηση λογαριασμού',
  'Action': 'Ενέργεια',
  'Add new address': 'Προσθήκη νέας διεύθυνσης',
  'Add your address': 'Προσθέστε τη διεύθυνσή σας',
  'Address book': 'Κατάλογος διευθύνσεων',
  'Addresses that are associated with your account':
    'Διευθύνσεις που σχετίζονται με το λογαριασμό σας.',
  'Are you sure you want to remove this address from your profile?':
    'Είστε βέβαιοι ότι θέλετε να αφαιρέσετε αυτήν τη διεύθυνση από το προφίλ σας;',
  'Average identification time': 'Μέσος χρόνος ταυτοποίησης',
  'Browse file': 'Αναζήτηση αρχείου',
  'Daily': 'Καθημερινά',
  'Default card': 'Προεπ. κάρτα',
  'Delete': 'Διαγραφή',
  'Delete card': 'Διαγραφή κάρτας',
  'Emulate failed palm identification': 'Εξομοίωση αποτυχημένης αναγνώρισης της παλάμης',
  'Emulated Failed Identification successfully':
    'Εξομοιώθηκε με επιτυχία η «Αποτυχημένη ταυτοποίηση».',
  'Emulation unexpected error': 'Μη αναμενόμενο σφάλμα εξομοίωσης.',
  'Enable beta languages': 'Ενεργοποίηση γλωσσών beta',
  'Enable new dashboard': 'Ενεργοποίηση νέου πίνακα ελέγχου',
  'Enable activity logs': 'Ενεργοποίηση καταγραφής δραστηριότητας',
  'Enroll': 'Εγγραφή',
  'Enter your address in the fields provided below':
    'Εισάγετε τη διεύθυνσή σας στα πεδία που παρέχονται παρακάτω.',
  'Failed to update organization information': 'Αποτυχία ενημέρωσης των πληροφοριών του οργανισμού',
  'Feature flags': 'Σημαίες λειτουργιών',
  'Guides & Tools': 'Οδηγοί και Εργαλεία',
  'Identification app': 'Εφαρμογή αναγνώρισης',
  'Identification failed': 'Η ταυτοποίηση απέτυχε',
  'Identify': 'Αναγνώριση',
  'Integrations': 'Ενσωματώσεις',
  'Invitation URL': 'URL πρόσκλησης',
  'Invitations': 'Προσκλήσεις',
  'Invite a new member to the organization!': 'Προσκαλέστε ένα νέο μέλος στον οργανισμό!',
  'Learn how to manage your organization\u2019s profile':
    'Μάθετε πώς να διαχειρίζεστε το προφίλ του οργανισμού σας.',
  'Learn more about new Integrations': 'Μάθετε περισσότερα σχετικά με τις νέες ενσωματώσεις.',
  'Let us know, and we\u2019ll add it to our roadmap!':
    'Ενημερώστε μας και θα το προσθέσουμε στο πλάνο μας!',
  'Make changes to your address': 'Κάντε αλλαγές στη διεύθυνσή σας.',
  'Members in organization': 'Μέλη στην οργάνωση',
  'Metrics': 'Μέθοδος μέτρησης',
  'Missing a feature?': 'Λείπει κάποια λειτουργία;',
  'Monthly': 'Μηνιαία',
  'NV': 'NV',
  'New feature!': 'Νέα λειτουργία!',
  'No organizations': 'Δεν υπάρχουν οργανισμοί',
  'No value': 'Χωρίς τιμή',
  'Number of scans': 'Αριθμός σαρώσεων',
  'Organization information updated': 'Οι πληροφορίες του οργανισμού ενημερώθηκαν',
  'Organization settings': 'Ρυθμίσεις οργανισμού',
  'Perform': 'Εκτέλεση',
  'Personal account': 'Προσωπικός λογαριασμός',
  'Personal details': 'Προσωπικά στοιχεία',
  'Provide your address details': 'Παραθέστε τα στοιχεία της διεύθυνσής σας.',
  'Remove': 'Αφαίρεση',
  'Remove address': 'Αφαίρεση διεύθυνσης',
  'Remove your address': 'Αφαιρέστε τη διεύθυνσή σας',
  'Role has been changed successfully': 'Ο ρόλος άλλαξε με επιτυχία',
  'Scans': 'Σαρώσεις',
  'Seconds': 'Δευτερόλεπτα',
  'Select your preferred address from the options below':
    'Επιλέξτε τη διεύθυνση που προτιμάτε από τις παρακάτω επιλογές.',
  'Show less': 'Εμφάνιση λιγότερων',
  'Show more': 'Εμφάνιση περισσότερων',
  'Successful identifications': 'Επιτυχείς ταυτοποιήσεις',
  'Successful scans per application': 'Επιτυχείς σαρώσεις ανά εφαρμογή',
  'System activity emulator': 'Εξομοιωτής δραστηριότητας του συστήματος',
  'Total scans': 'Σύνολο σαρώσεων',
  'Transfer ownership': 'Μεταβίβαση κυριότητας',
  'View device': 'Προβολή συσκευής',
  'Want to help members enroll their palm? Learn more!':
    'Θέλετε να βοηθήσετε τα μέλη στην καταγραφή της παλάμης τους? Μάθετε περισσότερα!',
  'We value your suggestions!': 'Εκτιμούμε τις προτάσεις σας!',
  'We\u2019d love your help in taking Keyo to the next level':
    'Θα θέλαμε πολύ τη βοήθειά σας ώστε να ανεβάσουμε την Keyo στο επόμενο επίπεδο.',
  'Yearly': 'Ετήσια',
  'You can do it with dashboard!': 'Μπορείτε να το κάνετε με τον πίνακα ελέγχου!',
  'You don\u2019t have any cards in your wallet': 'Δεν έχετε διαθέσιμες κάρτες στο πορτοφόλι σας.',
  'You haven\u2019t joined any organizations yet': 'Δεν έχετε ενταχθεί ακόμα σε κανέναν οργανισμό.',
  'Your address has been removed': 'Η διεύθυνσή σας αφαιρέθηκε.',
  'Your card has been deleted successfully': 'Η κάρτα σας διαγράφηκε με επιτυχία.',
  'Your changes have been saved successfully': 'Οι αλλαγές σας έχουν αποθηκευτεί με επιτυχία.',
  'Your organizations': 'Οι οργανισμοί σας',
  'byJoiningOrgYouAgreeToPartnersLegal': 'Με την εγγραφή σας στο {name} αποδέχεστε το {legal}.',
  'No results found': 'Δεν βρέθηκαν αποτελέσματα',
  'It looks like nothing matches your search':
    'Φαίνεται ότι τίποτα δεν ταιριάζει με την αναζήτησή σας.',
  'Export CSV': 'Εξαγωγή CSV',
  'byProceedingAgreeToTerms':
    'Συνεχίζοντας, αποδέχεστε τους {terms}, {privacy} και {messaging} της Keyo',
  'common': {
    createNewOrganization: 'Δημιουργία νέου οργανισμού',
    helpUsEnhance: 'Παρακαλούμε, βοηθήστε μας να βελτιώσουμε τις υπηρεσίες μας!',
    orgLegalMessage:
      'Με την ένταξή σας σε αυτόν τον οργανισμό, συμφωνείτε με το {legal} αυτών των συνεργατών.',
  },
  'components': {
    actionsDropdown: {
      hideMenu: 'Απόκρυψη μενού',
      showMenu: 'Εμφάνιση μενού',
    },
    btnResend: {
      resendSecurityCode: 'Επανάληψη αποστολής κωδικού ασφαλείας',
      resendSecurityCodeIn: 'Επανάληψη αποστολής κωδικού ασφαλείας σε {remaining}',
    },
    modals: {
      'Leave this window?': 'Αποχώρηση απο αυτό το παράθυρο;',
      'createOrganization': {
        created: 'Ο οργανισμός έχει δημιουργηθεί',
        failedCreated: 'Αποτυχία δημιουργίας οργανισμού',
        subtitle: 'Παρακαλούμε συμπληρώστε την παρακάτω φόρμα.',
        title: 'Δημιουργία Οργανισμού',
      },
      'leaveWithoutSaving?':
        'Είστε βέβαιοι ότι θέλετε να φύγετε από αυτό το παράθυρο; Οι αλλαγές που κάνατε δεν θα μπορούν να αποθηκευτούν.',
      'membersPause': {
        areYouSure: 'Είστε βέβαιοι ότι θέλετε να κάνετε παύση',
        confirmText:
          'Κάντε κλικ στην επιλογή «επιβεβαίωση» για να συνεχίσετε. Οι χρήστες θα λάβουν μια ειδοποίηση μέσω email για την παύση του λογαριασμού τους.',
        pauseError:
          'Μη αναμενόμενο σφάλμα κατά την προσπάθεια παύσης του {name}. Δοκιμάστε ξανά αργότερα',
        pauseSuccess: 'Το μέλος {name} έχει τεθεί σε παύση.',
        title: 'Παύση χρήστη',
      },
      'membersRenew': {
        areYouSure: 'Είστε βέβαιοι ότι θέλετε να κάνετε ανανέωση',
        confirmText:
          'Κάντε κλικ στην επιλογή «επιβεβαίωση» για να συνεχίσετε. Οι χρήστες θα λάβουν μια ειδοποίηση μέσω email για την ανανεωμένη συνδρομή τους.',
        renewError:
          'Μη αναμενόμενο σφάλμα κατά την προσπάθεια ανανέωσης του {name}. Παρακαλούμε δοκιμάστε ξανά αργότερα',
        renewSuccess: 'Έχει γίνει ανανέωση του μέλους {name}.',
        title: 'Ανανέωση χρήστη',
      },
      'videoPlayer': {
        notSupported: 'Το πρόγραμμα περιήγησής σας δεν υποστηρίζει το βίντεο',
      },
    },
  },
  'emulationActionUserSuccess':
    'Έχει πραγματοποιηθεί εξομοίωση "{action}" για τον {user} με επιτυχία.',
  'keyoDisclaimer':
    'Η Keyo, Inc. αποτελεί έναν επεξεργαστή τρίτου μέρους που διευκολύνει τη συναλλαγή σας με το {name}. Διαβάστε το {privacy} εδώ.',
  'layouts': {
    administrator: 'Διαχειριστής',
    apiReference: 'Αναφορά API',
    biometrics: 'Βιομετρικά στοιχεία',
    dashboard: 'Πίνακας ελέγχου',
    developers: 'Προγραμματιστές',
    developersHub: 'Hub προγραμματιστών',
    helpCenter: 'Κέντρο βοηθείας',
    keyoLogoSquare: 'Τετράγωνο λογότυπο της Keyo',
    keyoLogoWide: 'Ευρύ λογότυπο της Keyo',
    knowledgeHub: 'Hub γνώσης',
    logOut: 'Αποσύνδεση',
    personal: 'Προσωπικό',
    postmanCollection: 'Συλλογή Postman',
    preferences: 'Προτιμήσεις',
    profileSettings: 'Ρυθμίσεις προφίλ',
    settings: 'Ρυθμίσεις',
    systemActivityEmulator: 'Προσομοιωτής δραστηριότητας',
    toggleSidebar: 'Διακόπτης πλευρικής μπάρας',
    wallet: 'Πορτοφόλι',
    webhookManagement: 'Διαχείριση Webhook',
  },
  'logInTo': 'συνδεθείτε στο',
  'membersJoinedOverThePastMonth':
    'Τον περασμένο μήνα, {count} μέλος εντάχθηκε στον οργανισμό. | Τον περασμένο μήνα, {count} μέλη εντάχθηκαν στον οργανισμό.',
  'modules': {
    account: {
      common: {
        changeEmail: 'Αλλαγή email',
        changePhoneNumber: 'Αλλαγή αριθμού τηλεφώνου',
        securityCodeSentToMethod:
          'Ένας κωδικός ασφαλείας θα σταλεί στο {method}. Εισάγετε τον 6ψήφιο κωδικό παρακάτω.',
        securityCodeSentToPhone:
          'Ένας κωδικός ασφαλείας θα σταλεί στο {phone}. Εισάγετε τον 6ψήφιο κωδικό παρακάτω.',
      },
      components: {
        cardAccountDelete: {
          deleteAccountConfirmation: 'Είστε βέβαιοι ότι θέλετε να διαγράψετε τον λογαριασμό σας;',
          deletionNote: {
            part1: 'Λάβετε υπόψη ότι η διαγραφή του λογαριασμού σας, όπως αναφέρεται στο',
            part2:
              'θα έχει επίσης ως αποτέλεσμα τη διαγραφή των βιομετρικών δεδομένων σας σύμφωνα με τις',
            part3:
              'Αυτή η ενέργεια θα απενεργοποιήσει τις λειτουργίες βιομετρικού ελέγχου ταυτότητας και πληρωμών, πράγμα που σημαίνει ότι δεν θα μπορείτε πλέον να χρησιμοποιείτε τις βιομετρικές σας πληροφορίες για πρόσβαση ή πληρωμές σε αυτό το σύστημα.',
          },
          generalWarning:
            'Εάν αντιμετωπίσατε κάποιο πρόβλημα με τον λογαριασμό σας και χρειάζεστε βοήθεια, επικοινωνήστε μαζί μας για να μπορέσουμε να σας βοηθήσουμε.',
          ownerWarning:
            'Ως κάτοχος του οργανισμού, έχετε την επιλογή να διαγράψετε τον λογαριασμό σας. Ωστόσο, σημειώστε ότι εάν επιλέξετε να διαγράψετε τον λογαριασμό σας, θα χρειαστεί να ορίσετε κάποιον άλλο ως νέο κάτοχο του οργανισμού. Μόλις διαγραφεί ο λογαριασμός σας, δεν θα έχετε πλέον πρόσβαση στις λειτουργίες διαχείρισης και διοίκησης του οργανισμού.',
        },
        phoneAdd: {
          toAddEnterPhoneNumber:
            'Για να προσθέσετε τον αριθμό του τηλεφώνου σας, εισάγετε τον στο πεδίο που παρέχεται παρακάτω.',
        },
      },
      modals: {
        accountBiometricAlreadyExists: {
          description:
            'Λυπούμαστε, έχετε ήδη προσθέσει τα βιομετρικά σας δεδομένα στον λογαριασμό σας.',
        },
        accountBiometricConfirmEnroll: {
          almostDone: 'Είστε σχεδόν έτοιμοι!',
          deviceMissingError: 'Λείπει η συσκευή',
          followInstructions: 'Ακολουθήστε τις οδηγίες στο Keyo Wave+',
          organizationMissingError: 'Λείπει ο οργανισμός',
          startEnrollment: 'Έναρξη εγγραφής',
          startEnrollmentQuestion: 'Θα θέλατε να ξεκινήσετε την εγγραφή σας σε:',
        },
        accountBiometricDelete: {
          deleteBiometric: 'Διαγραφή βιομετρικών στοιχείων',
          description: {
            part1:
              'Εισάγετε τον κωδικό ασφαλείας που στείλαμε στο {method} παρακάτω. Μόλις πατήσετε',
            part3: ', τα βιομετρικά σας στοιχεία θα διαγραφούν οριστικά από την Keyo.',
          },
          heading: 'Διαγραφή των βιομετρικών στοιχείων σας',
        },
        accountBiometricDeleteSuccess: {
          description: 'Τα βιομετρικά σας δεδομένα έχουν διαγραφεί με βάση τα',
          heading: 'Τα βιομετρικά στοιχεία διαγράφηκαν',
        },
        accountDelete: {
          description: {
            part1:
              'Παρακαλούμε εισάγετε τον κωδικό ασφαλείας που σας στείλαμε στο {method}. Μόλις πατήσετε',
            part2: 'ο λογαριασμός σας θα διαγραφεί όπως αναφέρεται στο',
          },
          termsOfUse: 'Όροι Χρήσης.',
        },
        accountDeleteSuccess: {
          description: 'Ο λογαριασμός σας έχει διαγραφεί με βάση το',
          heading: 'Διαγραφή λογαριασμού',
        },
        accountEmailAddSuccess: {
          description:
            'Πλέον μπορείτε να χρησιμοποιήσετε το email σας για να συνδεθείτε στο λογαριασμό σας.',
        },
        accountEmailChangeStep1: {
          description:
            'Ένας κωδικός ασφαλείας θα σταλεί στο {email}. Εισάγετε τον 6ψήφιο κωδικό παρακάτω.',
        },
        accountEmailChangeStep2: {
          description:
            'Για να ενημερώσετε τη διεύθυνση email σας, εισάγετε τη νέα σας διεύθυνση email στο πεδίο που παρέχεται παρακάτω.',
          heading: 'Προσθήκη νέου email',
          labelNewEmail: 'Εισάγετε το νέο email',
        },
        accountEmailChangeStep3: {
          description:
            'Ένας κωδικός ασφαλείας θα σταλεί στο {newEmail}. Εισάγετε τον 6ψήφιο κωδικό παρακάτω.',
          heading: 'Επιβεβαίωση νέου email',
        },
        accountEmailChangeSuccess: {
          description:
            'Πλέον θα μπορείτε να χρησιμοποιήσετε το νέο σας email για να συνδεθείτε στο λογαριασμό σας.',
        },
        accountEmailStep1: {
          toAddEnterEmail:
            'Για να προσθέσετε το email σας, εισάγετε το email σας στο πεδίο που παρέχεται παρακάτω.',
        },
        accountPasswordChange: {
          confirmNewPassword: 'Επιβεβαίωση νέου κωδικού πρόσβασης',
          currentPassword: 'Τρέχων κωδικός πρόσβασης',
          description:
            'Για να ενημερώσετε τον κωδικό πρόσβασής σας, παρακαλούμε εισάγετε τον τρέχοντα κωδικό πρόσβασής στο πεδίο που παρέχεται παρακάτω.',
          heading: 'Αλλαγή κωδικού πρόσβασης',
          newPassword: 'Νέος κωδικός πρόσβασης',
          step1: 'Βήμα 1',
          step2: 'Βήμα 2',
        },
        accountPasswordChangeSuccess: {
          description:
            'Πλέον μπορείτε να χρησιμοποιήσετε τον νέο σας κωδικό πρόσβασης για να συνδεθείτε στο λογαριασμό σας.',
        },
        accountPhoneAddSuccess: {
          description:
            'Πλέον μπορείτε να χρησιμοποιήσετε τον αριθμό του τηλεφώνου σας για να συνδεθείτε στο λογαριασμό σας.',
        },
        accountPhoneChangeStep2: {
          description:
            'Για να ενημερώσετε τον αριθμό του τηλεφώνου σας, εισάγετε τον νέο σας αριθμό τηλεφώνου στο πεδίο που παρέχεται παρακάτω.',
          labels: {
            enterNewPhone: 'Εισάγετε νέο αριθμό τηλεφώνου',
          },
        },
        accountPhoneChangeStep3: {
          description:
            'Ένας κωδικός ασφαλείας θα σταλεί στο {newPhone}. Εισάγετε τον 6ψήφιο κωδικό παρακάτω.',
          heading: 'Επιβεβαίωση νέου αριθμού τηλεφώνου',
        },
        accountPhoneChangeSuccess: {
          description:
            'Πλέον θα μπορείτε να χρησιμοποιήσετε τον νέο αριθμό τηλεφώνου σας για να συνδεθείτε στο λογαριασμό σας.',
        },
        accountSecurityMfaConfirm: {
          areYouSureYouWantToChange2fa:
            'Είστε βέβαιοι ότι θέλετε να αλλάξετε την προεπιλεγμένη μέθοδο ελέγχου ταυτότητας δύο παραγόντων σε {method};',
          change2faAuthentication: 'Αλλαγή ελέγχου ταυτότητας δύο παραγόντων',
          changeTo: 'Αλλαγή σε {method}',
        },
        accountSecurityMfaSuccess: {
          description:
            'Έχετε αλλάξει την προεπιλεγμένη μέθοδο ελέγχου ταυτότητας δύο παραγόντων σε {method}.',
        },
        accountVerifyMethodSuccess: {
          methodHasBeenVerified: 'Το {method} επαληθεύτηκε με επιτυχία.',
        },
      },
      pages: {
        addAddress: 'Προσθήκη διεύθυνσης',
        profileEdit: {
          uploadInfo:
            'Μπορείτε να κάνετε μεταφόρτωησ ενός αρχείου .jpg ή .png του χρήστη έως και 2 MB.',
        },
        security: {
          addButton: 'Προσθήκη',
          changeButton: 'Αλλαγή',
          passwordPlaceholder: '*************',
          passwordTitle: 'Κωδικός πρόσβασης',
        },
        securityMfa: {
          receiveCodeMethod: 'Λάβετε τον κωδικό επαλήθευσης μέσω του {method}.',
          updateYourMfa:
            'Μπορείτε να ενημερώσετε την προεπιλεγμένη μέθοδο ελέγχου ταυτότητας δύο παραγόντων εδώ.',
          verifyMethod:
            'Για να λάβετε κωδικό επαλήθευσης μέσω του {method} θα πρέπει να ολοκληρώσετε την επαλήθευση.',
        },
      },
    },
    admin: {
      confirmOwnershipTransfer: 'Επιβεβαίωση μεταβίβασης κυριότητας',
      confirmOwnershipTransferDesc:
        'Ένας κωδικός ασφαλείας θα σταλεί στο {method}. Εισάγετε τον κωδικό ασφαλείας και πατήστε Επιβεβαίωση για να μεταφέρετε την κυριότητα του οργανισμού {nextOwner}.',
      dashboardDescription:
        'Καλώς ορίσατε στον Πίνακα ελέγχου του οργανισμού σας! Από εδώ μπορείτε να αποκτήσετε πρόσβαση σε εργαλεία προγραμματιστών, να αγοράσετε συσκευές, να προσκαλέσετε διαχειριστές, προγραμματιστές και μέλη και πολλά άλλα!',
      deviceInfo: {
        saveSuccess: 'Οι πληροφορίες της συσκευής αποθηκεύτηκαν',
        unexpectedError: 'Μη αναμενόμενο σφάλμα κατά την ενημέρωση του προφίλ της συσκευής',
      },
      enterEmail: 'Εισάγετε το email',
      enterSecurityCode:
        'Παρακαλούμε εισάγετε τον 6ψήφιο κωδικό που εστάλη στο {email} για να επιβεβαιώσετε τη μεταβίβαση της κυριότητας.',
      feedback: 'Έχετε να κάνετε κάποια σχόλια;\nΘα θέλαμε πολύ να μάθουμε περισσότερα!',
      invitationSent: 'Η πρόσκληση έχει σταλεί στο {email}',
      invite: 'Πρόσκληση',
      inviteMemberDescription: 'Μπορείτε να το κάνετε με τον Πίνακα ελέγχου!',
      inviteMemberTitle: 'Θέλετε να προσκαλέσετε ένα νέο μέλος;',
      inviteMembersToYourOrganization: 'Προσκαλέστε μέλη στον οργανισμό σας',
      inviteUsers: {
        message: 'Προσκαλέστε τους πρώτους σας χρήστες!',
      },
      learnMoreAboutKeyo: 'Μάθετε περισσότερα σχετικά με τον Πίνακα ελέγχου διαχειριστή της Keyo',
      letsBuild: 'Ας φτιάξουμε κάτι καταπληκτικό!',
      membersInYourOrganization: 'μέλη του οργανισμού σας',
      noDevices: {
        addDevices: 'προσθήκη συσκευών',
        displaySoon: 'Θα εμφανιστούν σύντομα εδώ',
        workingOnIt: 'Η ομάδα της Keyo εργάζεται πάνω σε αυτό!',
      },
      organizationDashboard: '{name} Πίνακας ελέγχου',
      resendSecurityCode: 'Επανάληψη αποστολής κωδικού ασφαλείας',
      scanningOnWave: 'Σάρωση της παλάμης σε συσκευή βιομετρικής αναγνώρισης Keyo Wave+',
      securityCode: 'Κωδικός ασφαλείας',
      userCheckMarkIcon: 'Τσεκαρισμένος χρήστης',
      usersListSearch: {
        pauseMembers: 'Παύση μελών',
        removeMembers: 'Αφαίρεση μελών',
        renewMembers: 'Ανανέωση μελών',
      },
    },
    apiCreds: {
      createSecretKey: 'Δημιουργία secret key',
      credentialsName: 'Όνομα διαπιστευτηρίων',
      keyDescription:
        'Το key θα σας επιτρέψει να πιστοποιήσετε τα αιτήματα API.\nΑποθηκεύετε πάντα το secret key σε μια ασφαλή και προστατευμένη τοποθεσία.',
      modals: {
        secretCreated: {
          description:
            'Φροντίστε να το αποθηκεύσετε - δεν θα μπορείτε να έχετε ξανά πρόσβαση σε αυτό. Αν χάσετε αυτό το secret key, θα πρέπει να δημιουργήσετε ένα νέο.',
        },
        secretRotateConfirm: {
          description: 'Είστε σίγουροι ότι θέλετε να περιστρέψετε το secret key;',
          title: 'Περιστροφή του secret key;',
        },
      },
      noApiCredentials: 'Δεν υπάρχουν διαπιστευτήρια API',
      rotate: 'Περιστροφή',
      secretKey: 'Secret key',
      toGetStarted:
        'Για να ξεκινήσετε κάντε κλικ στο «Δημιουργία secret key» για να δημιουργήσετε ένα.',
    },
    auth: {
      common: {
        activationAccountExpiredLink:
          'Ο σύνδεσμος ενεργοποίησης του λογαριασμού σας δεν είναι πλέον έγκυρος. Παρακαλούμε παραθέστε το email που σχετίζεται με το λογαριασμό σας στην Keyo για να ζητήσετε ένα νέο σύνδεσμο ενεργοποίησης.',
        activationLinkExpired: 'Ο σύνδεσμος ενεργοποίησης έχει λήξει',
        changeVerificationMethod: 'Αλλαγή μεθόδου επαλήθευσης',
        checkEmail: 'Ελέγξτε το email σας',
        completeFormToCreateAccount:
          'Παρακαλούμε συμπληρώστε την παρακάτω φόρμα για να δημιουργήσετε το λογαριασμό σας.',
        didNotGetCode: 'Δεν λάβατε κωδικό ασφαλείας;',
        emailOrPhoneNumberExample: "Παράδειγμα name{'@'}example.com ή +1123456789.",
        expiredLink:
          'Ο σύνδεσμος έχει λήξει ή είναι άκυρος. Παρακαλούμε ελέγξτε το email σας για περαιτέρω οδηγίες ή ζητήστε ένα νέο',
        forgotPassword: 'Ξεχάσατε τον κωδικό πρόσβασης;',
        pleaseAddMethod: 'Παρακαλούμε προσθέστε το {method}',
        pleaseVerifyMethod:
          'Για την προστασία της ταυτότητάς σας, παρακαλούμε επαληθεύστε το {method}.',
        resendEmail: 'Επανάληψη αποστολής email',
        unexpectedLoginSessionError: 'Απροσδόκητο σφάλμα της συνεδρίας σύνδεσης.',
        verifyMethod: 'Επαλήθευση {method}',
      },
      components: {
        policyInput: {
          readAndAccept: 'Έχω διαβάσει και αποδέχομαι',
        },
      },
      modals: {
        activationLinkSuccess: {
          description:
            'Παρακαλούμε ενεργοποιήστε το λογαριασμό σας χρησιμοποιώντας το μήνυμα που στείλαμε στο {method}.',
          heading: 'Ελέγξτε το {method}',
          methods: {
            email: 'email',
            phone: 'τηλέφωνο',
          },
        },
      },
      pages: {
        activationEmail: {
          cannotResendEmail: 'Δεν μπορούμε να ξαναστείλουμε email. Δοκιμάστε ξανά αργότερα.',
          checkEmail:
            'Ελέγξτε το email σας και κάντε κλικ στον σύνδεσμο ενεργοποίησης για να συνεχίσετε.',
          confirmationEmailResent: 'Σας έχει σταλεί ξανά ένα email επιβεβαίωσης!',
          emailSentTo: 'Στείλαμε ένα email επιβεβαίωσης στο',
        },
        activationPassword: {
          description:
            'Παρακαλούμε συμπληρώστε την παρακάτω φόρμα για να ενεργοποιήσετε το λογαριασμό σας.',
          heading: 'Δημιουργήστε τον κωδικό πρόσβασής σας',
          readAndAccept: 'Έχω διαβάσει και αποδέχομαι',
          readOur: 'Διαβάστε το',
        },
        activationSecureAccount: {
          description:
            'Παρακαλούμε, δημιουργήστε έναν κωδικό πρόσβασης για να συνδεθείτε και να διατηρήσετε τον λογαριασμό σας ασφαλή.',
          heading: 'Ασφαλίστε τον λογαριασμό σας',
        },
        activationVerifyInstruction: {
          ifYouHaveNotReceivedCode:
            'Εάν δεν έχετε λάβει τον κωδικό, ενημερώστε το {method} ή επιλέξτε μια εναλλακτική μέθοδο επαλήθευσης.',
          pleaseSelectPreferredMethodForVerifying:
            'Παρακαλούμε επιλέξτε τη μέθοδο που προτιμάτε για την επαλήθευση του λογαριασμού σας. Χρησιμοποιώντας την επιλεγμένη μέθοδο, θα μπορείτε να συνδεθείτε στο λογαριασμό σας.',
          verifyItIsYou: 'Επαληθεύστε την ταυτότητά σας',
        },
        activationVerifySet: {
          description:
            'Ένας κωδικός ασφαλείας θα σταλεί στο {recipient}.\nΠαρακαλούμε εισάγετε τον παρακάτω 6ψήφιο κωδικό.',
          verifyEmail: 'Επαλήθευση email',
          verifyPhoneNumber: 'Επαλήθευση αριθμού τηλεφώνου',
        },
        editMethod: {
          descriptionEmail: 'Παρακαλούμε, ενημερώστε το email σας παρακάτω.',
          descriptionPhone: 'Παρακαλούμε, ενημερώστε τον αριθμό του τηλεφώνου σας παρακάτω.',
          headingEmail: 'Επεξεργασία email',
          headingPhone: 'Επεξεργασία αριθμού τηλεφώνου',
        },
        forgotPasswordExpired: {
          description:
            'Ο παρεχόμενος σύνδεσμος επαναφοράς του κωδικού πρόσβασης έχει λήξει. Μπορείτε να ζητήσετε ένα άλλο παρακάτω.',
          heading: 'Ο σύνδεσμος έληξε',
          tryAgain: 'Δοκιμάστε ξανά',
        },
        forgotPasswordInstruction: {
          checkMessages: 'Ελέγξτε τα μηνύματά σας',
          description: {
            checkMethod:
              'Ένας σύνδεσμος επαναφοράς κωδικού πρόσβασης έχει σταλεί στο {method}. Ανοίξτε το σύνδεσμο για να συνεχίσετε.',
            enterEmail:
              'Για να επαναφέρετε τον κωδικό πρόσβασής σας, παρακαλούμε εισάγετε το email ή τον αριθμό τηλεφώνου σας παρακάτω.',
          },
          didNotReceiveEmail: 'Δεν λάβατε email;',
          didNotReceiveMessage: 'Δεν λάβατε μήνυμα;',
          heading: 'Ξέχασα τον κωδικό πρόσβασης',
          resendMessage: 'Επανάληψη αποστολής μηνύματος',
        },
        forgotPasswordSet: {
          createNewPassword: 'Δημιουργία νέου κωδικού πρόσβασης',
          enhanceAccountSecurity:
            'Βελτιώστε την ασφάλεια του λογαριασμού σας με έναν ισχυρό κωδικό πρόσβασης',
          youCanNowUseYourNewPassword:
            'Πλέον μπορείτε να χρησιμοποιήσετε τον νέο σας κωδικό πρόσβασης για να συνδεθείτε στο λογαριασμό σας.',
        },
        fullName: {
          enterDescription:
            'Παρακαλούμε συμπληρώστε την παρακάτω φόρμα για να ολοκληρώσετε την ενεργοποίηση του λογαριασμού σας.',
          enterHeading: 'Εισάγετε το ονοματεπώνυμό σας',
          reviewDescription: 'Βεβαιωθείτε ότι οι παρακάτω πληροφορίες είναι σωστές.',
          reviewHeading: 'Ελέγξτε το ονοματεπώνυμό σας',
        },
        kioskActivationResend: {
          resendActivationLink: 'Επανάληψη αποστολής του συνδέσμου ενεργοποίησης',
        },
        kioskActivationResendSuccess: {
          description: 'Ένας νέος σύνδεσμος πρόσκλησης έχει σταλεί στο ',
        },
        secureCode: {
          securityCodeHasBeenSent:
            'Ένας κωδικός ασφαλείας θα σταλεί στο {recipient}. \\ A Παρακαλούμε εισάγετε τον 6ψήφιο κωδικό παρακάτω',
          verifyIdentity: 'Επαληθεύστε την ταυτότητά σας',
        },
        signIn: {
          description: 'Παρακαλούμε συμπληρώστε την παρακάτω φόρμα για να συνδεθείτε.',
          descriptionInactive: 'Παρακαλούμε, εισάγετε το email ή τον αριθμό του τηλεφώνου σας.',
          wrongCredentials: {
            contactSupport: 'Επικοινωνήστε με την υποστήριξη.',
            text: 'Παρακαλούμε επαληθεύστε το email ή το τηλέφωνο και τον κωδικό πρόσβασής σας, διασφαλίζοντας ότι ταιριάζουν με τα διαπιστευτήρια που χρησιμοποιήθηκαν κατά την εγγραφή. Εάν το πρόβλημα παραμένει, παρακαλούμε',
          },
        },
        signup: {
          description:
            'Για να ενεργοποιήσετε τον Πίνακα ελέγχου της Keyo, παρακαλούμε συμπληρώστε την παρακάτω φόρμα',
          readAndAccept: 'Έχω διαβάσει και αποδέχομαι',
        },
      },
    },
    organization: {
      modals: {
        organizationRequirements: {
          requirements: {
            paymentProvider: {
              ensurePhoneNumberIsIn: 'Βεβαιωθείτε ότι ο αριθμός τηλεφώνου σας βρίσκεται στο ',
              enterPhoneMobileWallet:
                'Εισάγετε τον αριθμό τηλεφώνου που συνδέεται με το wallet του κινητού σας.',
              networks: '{networks} δίκτυο. | {networks} δίκτυα.',
            },
          },
          title: 'Απαιτήσεις οργανισμού',
          toJoinOrganizationCompleteRequirements:
            'Για να γίνετε μέλος σε αυτόν τον οργανισμό, παρακαλούμε συμπληρώστε τις ακόλουθες προϋποθέσεις.',
        },
      },
      requirements: {
        connectMobileWallet: 'Συνδέστε το wallet του κινητού σας',
      },
    },
    personal: {
      components: {
        activateAccountWidget: {
          description:
            'Παρακαλούμε ενεργοποιήστε το λογαριασμό σας χρησιμοποιώντας το μήνυμα που στείλαμε στο email ή στον αριθμό του τηλεφώνου σας.',
          done: 'Ολοκληρώθηκε',
        },
        cardChangeMfaMethod: {
          ifHaveNotReceivedCode:
            'Εάν δεν έχετε λάβει τον κωδικό, επιλέξτε μια εναλλακτική μέθοδο επαλήθευσης.',
        },
        invitationCard: {
          actionRequired: 'Απαιτείται ενέργεια',
          weArePleasedToInformYou:
            'Είμαστε στην ευχάριστη θέση να σας ενημερώσουμε ότι έχετε προσκληθεί να συμμετάσχετε στο {name}',
          youAreInvited: 'Έχετε προσκληθεί!',
        },
        joinedBannersWidgets: {
          welcomeToOrg: 'Καλώς ορίσατε στο {name}',
        },
        onBoardingProgress: {
          title: 'Onboarding - {percentage}% ολοκλήρωση',
        },
        policiesWidget: {
          description:
            'Θα βρείτε όλες τις πολιτικές που αποδεχτήκατε και υπογράψατε εδώ. Απλώς κάντε κλικ στην πολιτική που θέλετε να ελέγξετε.',
          heading: 'Η συγκατάθεσή σας είναι πολύ σημαντική για εμάς',
        },
        policyCard: {
          acceptanceDate: 'Ημερομηνία αποδοχής',
        },
        qrScanCard: {
          enrollStep1: 'Επισκεφθείτε μια συσκευή Keyo Wave+ για εγγραφή.',
          enrollStep2: {
            part2: '«Καταγράψτε την παλάμη σας»',
            part3: 'από το μενού.',
          },
          enrollStep3: {
            part2: '«Σάρωση κωδικού QR»',
            part3: 'και χρησιμοποιήστε το κινητό σας.',
          },
          enrollTitle: 'Η καταγραφή της παλάμης σας είναι εύκολη!',
          label: 'εγγραφή',
          unlockSubtitle:
            'Για να καταχωρήσετε την παλάμη σας, επισκεφθείτε μία από τις συσκευές Wave+ και πατήστε «Σάρωση κωδικού QR».',
          unlockTitle: 'Ξεκλειδώστε τον κόσμο με ένα κούνημα!',
        },
      },
      modals: {
        noAccessToCamera: {
          description: {
            part1:
              'Για να συνεχίσετε, θα πρέπει να επιτρέψετε την πρόσβαση στην κάμερα απο τις ρυθμίσεις σας. Έχουμε ετοιμάσει έναν οδηγό για εσάς',
            part2: 'εδώ.',
          },
          heading: 'Παρακαλούμε, δώστε έγκριση για πρόσβαση στην κάμερά σας',
        },
        qrScanner: {
          invalidQrMessage:
            'Ο κώδικας QR που σαρώσατε δεν είναι έγκυρος για εγγραφή. Παρακαλούμε δοκιμάστε ξανά αργότερα.',
        },
      },
      pages: {
        editProfile: 'Επεξεργασία προφίλ',
        home: {
          educationWidget: {
            description: 'Μάθετε περισσότερα σχετικά με το πώς να γίνετε μέλος.',
            title: 'Γιατί θα σας αρέσει να είστε μέλος της Keyo',
          },
          greeting: 'Γεια, {name} !',
          keyoLogo: 'Λογότυπο Keyo',
          networkWidget: {
            label: 'Το δίκτυό σας',
          },
          privacyDesignWidget: {
            title: 'Πώς η Keyo δημιουργεί με το απόρρητο ως μέρος του σχεδιασμού της',
          },
          setupWidget: {
            title: 'Ελέγξτε το προφίλ σας και επιλέξτε ένα εμφανιζόμενο όνομα',
          },
          transactionsWidget: {
            label: 'Συναλλαγές',
          },
          welcomeDescription: {
            keyoDashboard: 'Πίνακας ελέγχου Keyo',
            part1: 'Καλώς ήρθατε στο',
            part2:
              'Εδώ, μπορείτε να διαχειριστείτε το λογαριασμό σας, τα βιομετρικά σας στοιχεία και τις μεθόδους πληρωμής.',
          },
        },
        invitations: {
          accepted: 'Έγινε αποδοχή',
          decline: 'Απόρριψη',
          declined: 'Απορρίφθηκε',
          noInvitations: 'Δεν έχετε προσκλήσεις',
        },
      },
      store: {
        deletePhotoError: 'Σφάλμα κατά τη διαγραφή της φωτογραφίας',
        uploadPhotoError: 'Σφάλμα κατά τη μεταφόρτωση της φωτογραφίας',
        uploadPhotoSuccess: 'Η φωτογραφία έχει ενημερωθεί',
      },
    },
    webhooks: {
      active: 'Ενεργό',
      created: 'Δημιουργήθηκε',
      description:
        'Τα Webhooks είναι ένας τρόπος με τον οποίο ένα σύστημα μπορεί να ειδοποιεί ένα διαφορετικό, εντελώς ξεχωριστό σύστημα όταν συμβαίνουν ορισμένα γεγονότα. Πιο συγκεκριμένα, ένα webhook αποτελεί ένα τελικό σημείο API που δημιουργείτε στον διακομιστή σας, με κώδικα για την επεξεργασία των δεδομένων συμβάντος που προέρχονται από το backend της Keyo.',
      disabled: 'Απενεργοποιημένο',
      endpointURL: 'URL τελικού σημείου',
      hostedEndpoints: 'Εξυπηρετητής τελικών σημείων',
      notSet: 'Δεν έχει οριστεί',
      registeredEndpoints: 'Καταχωρημένα τελικά σημεία για ακρόαση και διαχείριση συμβάντων.',
      secret: 'Secret',
      set: 'Ορισμός',
      title: 'Webhooks',
      url: 'URL',
    },
    zendesk: {
      signingIn: 'Σύνδεση στο Zendesk',
    },
  },
  'ofTotalPages': 'από {totalPages}',
  'onceYouTapDeleteCardWillBeDeletedPermanently':
    'Μόλις πατήσετε το {action}, η κάρτα σας θα διαγραφεί οριστικά από το λογαριασμό σας.',
  'organizationHasARequirement':
    'Ο {name} έχει μια απαίτηση, κάντε κλικ στο κουμπί συνέχεια για να ολοκληρώσετε την απαίτηση.',
  'pages': {
    notFoundOrNoAccess: {
      message: 'Δεν υπάρχει τέτοια σελίδα ή δεν υπάρχει πρόσβαση ώστε να την δείτε',
    },
    org: {
      billingAddressForm: {
        cityLabel: 'Πόλη',
        countryLabel: 'Χώρα',
        stateLabel: 'Πολιτεία',
        stateRegionLabel: 'Πολιτεία/περιοχή',
        streetLabel: 'Οδός',
        zipLabel: 'Τ.Κ.',
      },
      businessProfileForm: {
        primaryContactFirstNameLabel: 'Κύρια επαφή (Όνομα)',
        primaryContactLastNameLabel: 'Κύρια επαφή (Επώνυμο)',
        primaryEmailLabel: 'Κύριο email',
      },
      devices: {
        deleteError: 'Παρουσιάστηκε σφάλμα κατά τη διαγραφή της φωτογραφίας της τοποθεσίας',
        description: 'Ακολουθούν οι συσκευές του οργανισμού σας.',
        generalInformation: 'Γενικές πληροφορίες',
        photoAdded: 'Προστέθηκε φωτογραφία τοποθεσίας',
        title: 'Συσκευές',
        unexpectedError: 'Μη αναμενόμενο σφάλμα κατά τη φόρτωση των δεδομένων της συσκευής',
        uploadDescription:
          'Μπορείτε να μεταφορτώσετε φωτογραφίες της περιοχής του τερματικού για να εμφανίσετε την τοποθεσία του.',
        uploadError: 'Παρουσιάστηκε σφάλμα κατά τη μεταφόρτωση της φωτογραφίας της τοποθεσίας',
        view: 'Προβολή',
      },
      generalForm: {
        cityRequired: 'Απαιτείται η πόλη',
        countryRequired: 'Απαιτείται η χώρα',
        nameRequired: 'Απαιτείται το όνομα',
        stateRequired: 'Απαιτείται η πολιτεία',
        streetRequired: 'Απαιτείται η οδός',
        zipRequired: 'Απαιτείται ο Τ.Κ.',
      },
      members: {
        exportCSV: 'Εξαγωγή CSV',
        members: 'Μέλη',
        orgMembers: 'Εδώ θα βρείτε τα μέλη της οργάνωσής σας.',
        pause: 'Παύση',
        pauseMember: 'Παύση μέλους',
        removeMember: 'Αφαίρεση μέλους',
        renew: 'Ανανέωση',
        renewMember: 'Ανανέωση μέλους',
        viewProfile: 'Προβολή προφίλ',
      },
      uploadFile: {
        alt2: 'εικονίδιο cross',
        text: 'Κάντε κλικ για μεταφόρτωση',
        uploadIcon: 'μεταφόρτωση εικόνας',
      },
    },
    pageNotFound: {
      message: 'Κάτι λείπει.',
    },
    sadAlt: 'sad',
  },
  'pleaseCompleteFormToAction':
    'Συμπληρώστε την παρακάτω φόρμα για να {action} τον λογαριασμό σας.',
  'utils': {
    validations: {
      confirm: {
        default: 'Παρακαλούμε επιβεβαιώστε το {name}',
      },
      email: {
        default:
          "Βεβαιωθείτε ότι έχετε δώσει μια σωστά διαμορφωμένη διεύθυνση ηλεκτρονικού ταχυδρομείου (π.χ. example{'@'}example.com).",
      },
      emailOrPhone: {
        default: 'Παρακαλούμε εισάγετε το email ή τον αριθμό τηλεφώνου',
        formatPhone:
          "Εισάγετε έναν έγκυρο αριθμό τηλεφώνου με τη μορφή '+11234567890'. Επιτρέπονται έως 15 ψηφία.",
        validEmailOrPhone:
          'Παρακαλούμε εισάγετε έναν έγκυρο αριθμό τηλεφώνου με τη μορφή "+11234567890" ή δώστε μια σωστά διαμορφωμένη διεύθυνση email (π.χ. example{\'@\'}example.com).',
      },
      letters: {
        default: 'Μπορεί να περιέχει μόνο γράμματα',
      },
      match: {
        default: 'Θα πρέπει να ταιριάζει με το πεδίο {name}.',
      },
      max: {
        default: 'Το μέγιστο μήκος είναι {length}',
      },
      mfaCode: {
        default: 'Θα πρέπει να είναι 6ψήφιος κωδικός.',
        shouldBeArray: 'Θα πρέπει να είναι σε διάταξη.',
      },
      min: {
        default: 'Το ελάχιστο μήκος είναι {length}',
      },
      name: {
        default: 'Επιτρέπονται μόνο γράμματα, παύλα, απόστροφος, τελεία.',
      },
      password: {
        default: 'Παρακαλούμε εισάγετε τον κωδικό πρόσβασής σας',
        weakPassword: 'Ο κωδικός πρόσβασης είναι ασθενής.',
      },
      phone: {
        default: 'Μη έγκυρος αριθμός.',
        possiblePhone: 'Θα πρέπει να είναι διεθνούς μορφής και να αρχίζει με +.',
      },
      required: {
        default: 'Αυτό το πεδίο είναι υποχρεωτικό',
      },
      url: {
        default: 'Μη έγκυρη διεύθυνση URL.',
      },
    },
  },
  'vs yesterday': 'έναντι χθες',
  'welcomeTo': 'Καλωσήρθατε στο {name}!',
  'welcomeToOrgInvitation': 'Καλώς ήρθατε! @:youHaveBeenInvitedBy @:pleaseCompleteFormToAction',
  'welcomeToOrgJoin': '@:welcomeTo @:pleaseCompleteFormToAction',
  'welcomeToOrgJoinLogin': '@:welcomeTo',
  'youHaveBeenInvitedBy': 'Έχετε προσκληθεί από το {name}.',
  'youHavePendingInvitationsPleaseCheckThem':
    'Έχετε {amount} εκκρεμείς πρόσκληση-ελέγξτε τες τώρα! | Έχετε {amount} εκκρεμείς προσκλήσεις—παρακαλούμε ελέγξτε τις!',
}
